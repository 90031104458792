import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/src/components/ui/dialog";
import { Button } from "@/src/components/ui/button";
import { LoaderButton } from "@/src/components/ui-extended/loader-button";

const SubmissionBlockModal = ({ isOpen, setIsOpen }) => {
	return (
		<Dialog
			open={isOpen}
			onOpenChange={setIsOpen}
			aria-describedby="submission-block-modal"
		>
			<DialogContent className="flex w-full max-w-[360px] rounded-[10px] px-3 py-4 pl-6">
				<div className="flex gap-2">
					<div className="flex-1">
						<div className="flex flex-col gap-y-2">
							<DialogHeader>
								<DialogTitle className="flex items-center justify-between">
									<span className="text-xl font-semibold leading-[30px] tracking-[-0.22px]">
										Unable to Book This Service
									</span>
								</DialogTitle>
								<DialogDescription className="sr-only">
									Unable to Book Service
								</DialogDescription>
							</DialogHeader>
							<p className="text-sm font-semibold tracking-[-0.1px] text-main-7">
								Please contact the Health Services Center at
								+188893 999 or walk in to at 200 University
								Avenue
							</p>

							<p className="py-3 text-sm font-semibold tracking-[-0.1px] text-main-7">
								A representative will assist you with booking
								this service
							</p>
						</div>
						<DialogFooter className="mt-2 flex w-full !flex-col !items-center  gap-y-2">
							<LoaderButton
								className="mx-0 w-full text-white"
								type="submit"
								loaderSize={20}
							>
								<a href="tel:+188893999">
									Call Health Services
								</a>
							</LoaderButton>
							<Button
								className="mx-0 w-full"
								variant="outline"
								onClick={() => {
									setIsOpen(false);
								}}
							>
								Close
							</Button>
						</DialogFooter>
					</div>
					<DialogClose className="flex items-start">
						<i className="mgc_close_line mt-1.5 text-lg before:!text-main-4" />
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default SubmissionBlockModal;
