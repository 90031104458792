import { Button } from "@/src/components/ui/button";
import { useNavigate, useParams } from "react-router";
import welcomeImage from "@/public/assets/calendar_icon.svg";
import CancelAppointmentModal from "./components/CancelAppointmentModal";
import { useState } from "react";
import { useGetScheduleData } from "@/src/store/slices/scheduleSlice";
import { ScheduleStatus } from "@/src/utils/constants";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import moment from "moment";

const ApppointmentFinished = () => {
	const { appointment_code } = useParams();
	const { data, isLoading } = useGetScheduleData(
		{
			scheduling_code: appointment_code,
		},
		true
	);
	const navigate = useNavigate();
	const status = data?.data.status;
	const showStatus =
		ScheduleStatus[status] == "pending" ||
		ScheduleStatus[status] == "confirmed";
	const [isCancelAppointmentOpen, setIsCancelAppointmentOpen] =
		useState(false);
	return (
		<>
			<div className="flex min-h-[100svh] w-full flex-col items-center justify-center gap-y-4 bg-[#F3F3F3] px-4 py-8 md:flex-row">
				<div className="flex w-full items-center justify-center gap-x-2 rounded-md py-3 text-lg text-main-2">
					<img src={welcomeImage} alt="" className="w-[40%]" />
				</div>
				<div className="flex  w-full flex-col gap-y-4">
					<div className="w-fit">
						{ScheduleStatus[status] == "rescheduled" && (
							<div className="flex flex-col  rounded-[12px] bg-white px-6 py-4">
								<h3 className=" text-[24px] font-semibold text-main-1">
									Appointment Rescheduled!
								</h3>
								<p className="max-w-[294px] pt-2 text-sm text-[#6D748D]">
									You have successfully rescheduled your
									appointment
								</p>
							</div>
						)}

						{ScheduleStatus[status] == "cancelled" ? (
							<div className="flex w-fit flex-col rounded-[12px] bg-white px-6 py-4">
								<h3 className=" text-[24px] font-semibold text-main-1">
									Appointment Cancelled!
								</h3>
								<p className="max-w-[294px] pt-2 text-sm text-[#6D748D]">
									You have successfully cancelled your
									appointment. You may choose to reschedule
									for another time. Thank You!
								</p>
								<div className="pt-6 text-center md:text-left">
									<Button
										variant={"default"}
										className="bg-main-2"
										onClick={() =>
											navigate(
												`/schedule/${appointment_code}/reschedule`
											)
										}
									>
										Reschedule
									</Button>
								</div>
							</div>
						) : (
							<div className="flex flex-col gap-y-4 rounded-[12px] bg-white px-6 py-4">
								<div className="flex flex-col">
									<h1 className=" text-center text-[22px] font-semibold md:text-left">
										Appointment Details
									</h1>
									<p className="text-[#323539]">
										Check your email for confirmation
									</p>
								</div>

								<div>
									<ul className="flex flex-col gap-y-2">
										<li className="flex items-center gap-x-2">
											<i className="mgc_schedule_line before:!text-primary" />
											<p className="font-bold text-main-1">
												<span>
													{data?.data
														?.appointment_date
														? moment(
																data?.data
																	?.appointment_date
															)?.format(
																"Do MMMM YYYY"
															)
														: "N/A"}
												</span>{" "}
												at{" "}
												<span>
													{data?.data
														?.appointment_date
														? moment(
																data?.data
																	?.appointment_date
															)?.format("h:mm a")
														: "N/A"}{" "}
												</span>
											</p>
										</li>
										<li className="flex items-center gap-x-2">
											<i className="mgc_building_1_line before:!text-primary" />
											<p className="text-main-1">
												{data?.data?.station?.name ??
													"N/A"}
											</p>
										</li>
										{showStatus && (
											<>
												<li className="flex items-center gap-x-2 pl-4">
													<p className="text-main-1">
														Status:{" "}
														<span className="capitalize">
															{ScheduleStatus[
																status
															] ?? "N/A"}
														</span>
													</p>
												</li>
											</>
										)}

										<li className="flex items-center gap-x-2">
											<i className="mgc_building_1_line before:!text-primary" />
											<p className="text-main-1">
												{data?.data?.station?.business
													?.name ?? "N/A"}
											</p>
										</li>
										<li className="flex items-center gap-x-2">
											<i className="mgc_location_line before:!text-primary" />
											<p className="text-[#6D748D]">
												{data?.data?.station?.location
													?.address ?? "N/A"}
											</p>
										</li>
									</ul>
								</div>
								<div className="flex w-full items-center gap-x-4 pt-4">
									{/* <Button variant={"outline-destructive"}>
										Cancel Appointment
									</Button> */}

									<Button
										className="w-full"
										variant={"default"}
										onClick={() =>
											navigate(
												`/schedule/${appointment_code}/reschedule`
											)
										}
									>
										Reschedule
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<CancelAppointmentModal
				data={data?.data}
				appointment_code={appointment_code}
				isOpen={isCancelAppointmentOpen}
				setIsOpen={setIsCancelAppointmentOpen}
			/>
			<RequestIsLoading
				isLoading={isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	);
};

export default ApppointmentFinished;
