import React, { useState } from "react";
import {
	google,
	outlook,
	office365,
	yahoo,
	ics,
	CalendarEvent,
} from "calendar-link";
import { Button } from "@/src/components/ui/button";
import {
	IoLogoApple,
	IoLogoGoogle,
	IoLogoMicrosoft,
	IoLogoYahoo,
} from "react-icons/io5";

const AddToCalendarButton = ({ data }) => {
	const [isOpen, setIsOpen] = useState(false);
	const event: CalendarEvent = {
		title: "Appointment",
		description: `Appointment at ${data?.station.name}`,
		start: data?.appointment_date,
		// duration: [8, "hour"],
	};

	const googleUrl = google(event);
	const icsUrl = ics(event);
	const outlookUrl = outlook(event);
	const yahooUrl = yahoo(event);
	const office365Url = office365(event);
	return (
		<div className="relative inline-block w-full">
			<Button
				type="button"
				className="w-full bg-primary"
				disabled={data?.status == "expired"}
				onClick={() => setIsOpen(!isOpen)}
			>
				Add to Calendar
			</Button>
			{isOpen && (
				<ul
					className={`${isOpen ? "opacity-100" : "opacity-0"} absolute left-0 top-full z-10 w-full bg-white`}
				>
					<li>
						<a
							className="flex items-center gap-2 whitespace-nowrap px-4 py-2 text-zinc-800 hover:bg-primary/5"
							href={icsUrl}
							rel="noreferrer"
							target="_blank"
						>
							<IoLogoApple />
							Add to Apple (iCal)
						</a>
					</li>
					<li>
						<a
							className="flex items-center gap-2 whitespace-nowrap px-4 py-2 text-zinc-800 hover:bg-primary/5"
							href={googleUrl}
							rel="noreferrer"
							target="_blank"
						>
							<IoLogoGoogle />
							Add to Google Calendar
						</a>
					</li>
					<li>
						<a
							className="flex items-center gap-2 whitespace-nowrap px-4 py-2 text-zinc-800 hover:bg-primary/5"
							href={office365Url}
							rel="noreferrer"
							target="_blank"
						>
							<IoLogoMicrosoft />
							Add to Office365
						</a>
					</li>
					<li>
						<a
							className="flex items-center gap-2 whitespace-nowrap px-4 py-2 text-zinc-800 hover:bg-primary/5"
							href={outlookUrl}
							rel="noreferrer"
							target="_blank"
						>
							<IoLogoMicrosoft />
							Add to Outlook
						</a>
					</li>
					<li>
						<a
							className="flex items-center gap-2 whitespace-nowrap px-4 py-2 text-zinc-800 hover:bg-primary/5"
							href={yahooUrl}
							rel="noreferrer"
							target="_blank"
						>
							<IoLogoYahoo />
							Add to Yahoo
						</a>
					</li>
				</ul>
			)}
		</div>
	);
};

export default AddToCalendarButton;
