const CustomAppointmentSvg = ({
	width,
	height,
	className,
}: {
	width: number;
	height: number;
	className?: string;
}) => {
	const storedTheme = localStorage.getItem("theme");
	const primaryColor = storedTheme || "#25437F";

	return (
		<>
			<svg
				width={width || 80}
				height={height || 40}
				viewBox="0 0 359 400"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
			>
				<g opacity="0.3">
					<path
						d="M67.2592 209.581C65.4116 210.655 65.4116 212.373 67.2377 213.426L79.0537 220.279C80.8798 221.332 83.8445 221.354 85.6921 220.279L97.5725 213.426C99.4201 212.373 99.4201 210.633 97.594 209.581L85.778 202.727C83.9519 201.653 80.9657 201.675 79.1396 202.727L67.2592 209.581Z"
						fill="white"
					/>
					<g opacity="0.29">
						<path
							d="M92.7865 194.844C90.9389 195.897 90.9389 197.637 92.765 198.69L104.581 205.543C106.407 206.596 109.393 206.596 111.219 205.543L123.1 198.69C124.947 197.616 124.947 195.897 123.121 194.844L111.305 187.991C109.479 186.917 106.515 186.917 104.667 187.991L92.7865 194.844Z"
							fill="white"
						/>
					</g>
					<path
						d="M93.4936 171.406C91.646 172.481 91.646 174.199 93.4721 175.252L105.288 182.105C107.114 183.179 110.079 183.179 111.926 182.105L123.807 175.252C125.655 174.199 125.655 172.459 123.828 171.406L112.012 164.553C110.186 163.5 107.2 163.5 105.374 164.553L93.4936 171.406Z"
						fill="#58AFFB"
					/>
					<path
						d="M27.3705 209.584C25.5229 210.637 25.5229 212.377 27.349 213.43L39.165 220.283C40.9911 221.357 43.9774 221.336 45.8035 220.283L57.7054 213.408C59.553 212.356 59.5529 210.615 57.7268 209.563L45.9109 202.709C44.0847 201.657 41.0985 201.657 39.2724 202.709L27.3705 209.584Z"
						stroke="#E6F4FF"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M92.6107 223.979C90.7631 225.053 90.7631 226.772 92.5892 227.824L104.405 234.678C106.231 235.73 109.196 235.752 111.044 234.678L122.924 227.824C124.772 226.772 124.772 225.031 122.946 223.979L111.13 217.125C109.304 216.073 106.317 216.073 104.491 217.125L92.6107 223.979Z"
						fill="#58AFFB"
					/>
				</g>
				<path
					d="M18.9635 270.169C-5.8501 284.498 -5.979 307.7 18.6627 322.03L134.33 389.252C158.972 403.582 199.039 403.582 223.853 389.252L340.272 322.03C365.086 307.7 365.215 284.498 340.573 270.169L224.905 202.946C200.264 188.617 160.197 188.617 135.383 202.946L18.9635 270.169Z"
					fill={primaryColor}
				/>
				<path
					d="M18.6566 259.499L134.324 326.721C158.966 341.051 199.033 341.051 223.847 326.721L340.266 259.499C352.748 252.302 358.978 242.828 358.957 233.396L359 246.781C359.021 256.234 352.791 265.686 340.309 272.883L223.89 340.106C199.076 354.435 159.009 354.435 134.367 340.106L18.6996 272.883C6.45395 265.772 0.331085 256.47 0.309601 247.146L0.266663 233.762C0.288147 243.086 6.41098 252.388 18.6566 259.499Z"
					fill="#A6D0F4"
				/>
				<path
					d="M18.9635 207.659C-5.8501 221.988 -5.979 245.191 18.6627 259.52L134.33 326.742C158.972 341.072 199.039 341.072 223.853 326.742L340.272 259.499C365.086 245.169 365.215 221.967 340.573 207.637L224.905 140.415C200.264 126.086 160.197 126.086 135.383 140.415L18.9635 207.659Z"
					fill="#D9E8FF"
				/>
				<path
					d="M95.3137 275.501L81.5642 264.244C77.9764 261.301 75.8925 256.918 75.8925 252.278V96.543C75.8925 96.543 77.6112 121.4 88.1382 115.642C100.899 108.66 107.731 127.866 107.731 127.866L111.512 115.771C111.512 115.771 112.092 119.831 123.844 115.771C134.242 112.162 95.3137 275.501 95.3137 275.501Z"
					fill="#D7DBDF"
				/>
				<path
					d="M89.8964 149.734L76.1039 141.42L76.1684 101.374C76.1684 101.374 75.2446 86.7223 84.3321 82.3826L226.167 1.06713C228.38 -0.200399 231.065 -0.350784 233.407 0.680429L247.779 6.99661L250.766 11.8304L89.8964 149.734Z"
					fill="#753939"
				/>
				<path
					d="M89.9022 268.282V98.1536C89.9022 95.5326 91.3202 93.105 93.6404 91.8375L240.61 10.7798C245.422 8.13732 251.309 11.5962 251.309 17.096V184.561C251.309 187.16 249.912 189.545 247.656 190.834L100.666 274.556C95.8747 277.284 89.9022 273.804 89.9022 268.282Z"
					fill="#F9F9F9"
				/>
				<path
					d="M251.299 57.6544L89.8925 149.712V98.151C89.8925 98.151 89.5273 93.7899 93.6306 91.8349L247.346 6.78125C247.346 6.78125 251.127 7.79098 251.664 16.2126L251.299 57.6544Z"
					fill="#AA5757"
				/>
				<path
					d="M216.683 57.8375C211.513 57.0327 208.105 51.3432 209.073 45.1295C210.04 38.9158 215.015 34.5311 220.185 35.3358C225.356 36.1406 228.763 41.8301 227.796 48.0438C226.828 54.2574 221.853 58.6422 216.683 57.8375Z"
					fill="#663333"
				/>
				<path
					d="M124.617 110.279C119.446 109.474 116.039 103.785 117.006 97.5709C117.973 91.3572 122.949 86.9725 128.119 87.7772C133.289 88.582 136.696 94.2715 135.729 100.485C134.762 106.699 129.787 111.084 124.617 110.279Z"
					fill="#663333"
				/>
				<path
					d="M123.283 89.1059C123.047 89.0414 122.982 88.934 122.832 88.7621C122.832 88.7621 119.545 89.9867 118.686 92.9729C118.686 92.9729 130.244 98.4942 134.154 90.0082C137.054 83.692 132.908 62.0365 118.063 58.3843C103.217 54.7321 104.872 70.7159 104.872 70.7159L110.608 67.3C110.608 67.3 112.906 58.0406 121.629 66.2258C130.351 74.4111 133.294 91.9847 123.283 89.1059Z"
					fill="#D7DBDF"
				/>
				<path
					d="M180.617 115.746V138.54L161.969 148.68V126.209L180.617 115.746Z"
					fill="#D7DBDF"
				/>
				<path
					d="M206.465 100.963V123.757L187.817 133.897V111.425L206.465 100.963Z"
					fill="#D7DBDF"
				/>
				<path
					d="M234.391 85.9277V108.722L215.743 118.862V96.3903L234.391 85.9277Z"
					fill="#D7DBDF"
				/>
				<path
					d="M152.906 132.225V155.019L134.258 165.159V142.666L152.906 132.225Z"
					fill="#D7DBDF"
				/>
				<path
					d="M234.742 120.752V143.546L216.094 153.708V131.214L234.742 120.752Z"
					fill="#D7DBDF"
				/>
				<path
					d="M207.031 137.229V160.023L188.383 170.163V147.691L207.031 137.229Z"
					fill="#D7DBDF"
				/>
				<path
					d="M234.742 157.359V180.153L216.094 190.294V167.822L234.742 157.359Z"
					fill="#D7DBDF"
				/>
				<path
					d="M207.031 173.838V196.632L188.383 206.772V184.3L207.031 173.838Z"
					fill="#D7DBDF"
				/>
				<path
					d="M180.617 152.137V174.931L161.969 185.071V162.578L180.617 152.137Z"
					fill="#D7DBDF"
				/>
				<path
					d="M152.906 168.598V191.392L134.258 201.554V179.06L152.906 168.598Z"
					fill="#D7DBDF"
				/>
				<path
					d="M180.617 188.729V211.544L161.969 221.684V199.191L180.617 188.729Z"
					fill="#D7DBDF"
				/>
				<path
					d="M152.906 205.205V227.999L134.258 238.161V215.668L152.906 205.205Z"
					fill="#D7DBDF"
				/>
				<path
					d="M126.371 146.879V169.673L107.723 179.835V157.341L126.371 146.879Z"
					fill="#AA5757"
				/>
				<path
					d="M126.371 183.27V206.064L107.723 216.204V193.732L126.371 183.27Z"
					fill="#AA5757"
				/>
				<path
					d="M215.463 36.4085C215.226 36.344 215.269 36.344 215.119 36.1936C215.119 36.1936 211.703 37.2893 210.865 40.297C210.865 40.297 222.423 45.8183 226.333 37.3323C229.234 31.0161 225.087 9.36061 210.242 5.7084C195.397 2.05618 197.03 17.7822 197.03 17.7822L202.787 14.4952C202.787 14.4952 205.086 5.38614 213.809 13.5499C222.531 21.7137 225.453 39.2873 215.463 36.4085Z"
					fill="#D7DBDF"
				/>
			</svg>
		</>
	);
};

export default CustomAppointmentSvg;
