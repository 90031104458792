import { z } from "zod";

interface TimeSlot {
	start_time: string;
	end_time: string;
}

interface Business {
	id: number;
	name: string;
	address: string;
	logo: string | null;
}
interface Location {
	id: number;
	name: string;
	address: string;
	stations: Station[];
	services: Service[];
}

export interface ScheduleStationInfo {
	id: number;
	name: string;
}
export interface Service {
	id: number;
	name: string;
	title: string;
	business_id: number;
	time_in_minute: number;
	appointment_methods: { id: string; name: string }[];
	description: string;
	created_at: string;
	updated_at: string;
}

export interface Station {
	id: number;
	name: string;
	address: string;
	admin_id: number;
	location_id: number;
	business_id: number;
	location: Location;
	business: Business;
}

interface CustomIntake {
	id: number;
	key: string;
	name: string;
	subtitle: string;
	field_requirement: string;
	apply_to: string;
	type: string;
	created_at: string;
	updated_at: string;
	relation_id: number;
	long_text?: number;
	options?: string[];
	allow_multiple?: number;
}

interface ScheduleSetting {
	id: number;
	is_visible: number;
	setting_id: number;
	created_at: string;
	updated_at: string;
	auto_approve: number;
	schedule_block_away_count: number;
	schedule_block_away_option: string;
	join_from_qr: number;
	join_from_url: number;
	is_open: number;
	url_code: string;
	schedule_block_in_minute: number;
	qr_code: string;
	join_url: string;
	qr_code_url: string;
}

export interface ScheduleStationData {
	business: ScheduleBusinessData;
	location: ScheduleLocationData;
	time_slots: TimeSlot[];
	appointment_types: any[];
	scheduling_through: string;
	station: Station;
	stations: Station[];
	services: Service[];
	custom_intakes: CustomIntake[];
	schedule_setting: ScheduleSetting;
	validators: any[];
}

export interface ScheduleLocationData {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	time_zone: string;
	average_waiting_time: number | null;
	use_average_wait_time: number;
	image: string | null;
	phone_number: string | null;
	description: string | null;
	url_code: string;
	qr_code: string;
	stations: Station[];
	services: Service[];
	validators: any[];
}

export interface ScheduleBusinessData {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	logo_url: string;
	admin_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	theme: string;
	business_category_id: number | null;
	schedule_auto_confirm: number;
	is_schedule_notify_open: number;
	average_waiting_time: number | null;
	use_average_wait_time: number;
	zip_code: string;
	locations: Location[];
	logo: string | null;
	validators: any[];
}

export interface ScheduleInfoData
	extends ScheduleBusinessData,
		ScheduleStationData,
		ScheduleLocationData {}

// Define the full response type

export interface ScheduleInfoResponse {
	status: boolean;
	message: string;
	code_type: string;
	verification: {
		action_on: "yes" | "no";
		action_url: string;
		field_title: string;
		is_enabled: 1 | 0;
	};
	validators: any[] | undefined;
	data: ScheduleBusinessData & ScheduleStationData & ScheduleLocationData;
}

export interface ScheduleBusinessResponse {
	status: boolean;
	message: string;
	data: ScheduleBusinessData;
}
export interface ScheduleStationResponse {
	status: boolean;
	message: string;
	data: ScheduleStationData;
}

export const createScheduleCustomFieldsSchema = (
	customIntakes: CustomIntake[]
) => {
	const customIntakeSchema: Record<string, z.ZodTypeAny> = {};

	customIntakes.forEach((intake) => {
		let fieldSchema: z.ZodTypeAny;

		switch (intake.type) {
			case "text":
				fieldSchema = z.string();
				break;
			case "numeric":
				fieldSchema = z.string();
				break;
			case "boolean":
				fieldSchema = z.boolean();
				break;
			case "date":
				fieldSchema = z.date();
				break;
			case "date_range":
				fieldSchema = z.object({
					from: z.date(),
					to: z.date(),
				});
				break;
			case "dropdown":
				fieldSchema = z.array(
					z.object({
						label: z.string(),
						value: z.string(),
					})
				);
				break;
			case "checkbox":
				fieldSchema = z
					.array(z.string())
					.nonempty(`${intake.name} is required`);
				break;

			case "attachment":
				fieldSchema = z.instanceof(File);
				break;
			default:
				fieldSchema = z.any();
		}

		if (intake.field_requirement === "yes") {
			fieldSchema = z.preprocess(
				(val) => {
					if (val === "" || val === null || val === undefined) {
						return undefined;
					}
					return val;
				},
				fieldSchema.refine((val) => val !== undefined, {
					message: `${intake.name} is required`,
				})
			);
		} else {
			fieldSchema = fieldSchema.optional();
		}

		customIntakeSchema[intake.key] = fieldSchema;
	});

	// Return just the custom_intakes schema
	return z.object({
		custom_intakes: z.object(customIntakeSchema).optional(),
	});
};

export interface CustomerValidationResponse {
	status: boolean;
	message: string;
	customer_verification_token: string | null;
	code_type: string;
	data: {
		id: number;
		name: string;
		location: {
			id: number;
			name: string;
			address: string;
			image_url: string | null;
		};
		business: { id: number; name: string; logo: string | null };
		services: { id: number; name: string; time_in_minute: number }[];
		appointment_types: any[];
		time_slots: { start_time: string; end_time: string }[];
	};
}
