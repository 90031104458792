import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/src/components/ui/dialog";
import React from "react";
import { Button } from "@/src/components/ui/button";
import useCustomToast from "@/src/components/CustomToast";
import { useNavigate, useParams } from "react-router";
import { useUpdateAppointment } from "@/src/store/slices/scheduleSlice";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "@/src/components/Loader/Loader";

const CheckInModal = (data) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const { appointment_code } = useParams();
	const toast = useCustomToast();
	const navigate = useNavigate();
	const { mutate: updateAppointment, isPending } = useUpdateAppointment();
	const queryClient = useQueryClient();
	const checkedIn = data?.data?.data?.check_in_status == null ? false : true;

	const updateStatus = () => {
		const status = checkedIn ? "undo_checkin" : "check_in";
		updateAppointment(
			{
				appointmentId: appointment_code!,
				data: {
					action: status,
				},
			},
			{
				onSuccess: () => {
					toast("Appointment updated successfully.", {
						type: "success",
						id: "updated-appointment",
					});
					setIsOpen(false);
					navigate(`/schedule/${appointment_code}/status`);
					queryClient.invalidateQueries({
						queryKey: ["scheduleStatus"],
					});
				},
				onError: () => {
					toast(
						"Failed to update the appointment. Please try again.",
						{
							type: "error",
							id: "updated-appointment",
						}
					);
				},
			}
		);
	};

	return (
		<Dialog
			open={isOpen}
			onOpenChange={(state) => {
				setIsOpen(state);
			}}
		>
			<DialogTrigger
				className="h-10 w-full rounded-md bg-primary px-4 py-2 text-primary-foreground text-white hover:bg-primary/90"
				onClick={() => setIsOpen(true)}
			>
				<span>{checkedIn ? "Undo Check-in " : "Check-in"}</span>
			</DialogTrigger>

			<DialogContent className="w-full max-w-[360px] gap-8 rounded-[10px] p-6">
				<div className=" flex flex-col gap-y-6">
					<div className="flex flex-col space-y-2">
						<DialogHeader>
							<DialogTitle className="flex items-center justify-between">
								<h1 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px]">
									Check-in to Location
								</h1>
								<DialogClose>
									<i className="mgc_close_line before:!text-main-4" />
								</DialogClose>
							</DialogTitle>
						</DialogHeader>
						<p className="flex items-center gap-2 font-semibold text-primary">
							<i className="mgc_schedule_line before:!text-primary" />
							{data?.data?.data?.appointment_date
								? moment(
										data?.data?.data?.appointment_date
									)?.format("Do MMMM YYYY")
								: "N/A"}
						</p>
						<p className="text-base tracking-[-0.16px] text-main-1">
							Once you&apos;re present at the location, please
							check-in to let the staff know you&apos;re here.
						</p>
					</div>

					<DialogFooter className="flex w-full !flex-row space-x-2.5">
						<Button
							className="flex-1"
							variant="ghost"
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</Button>
						<Button
							className="flex-1 text-white"
							type="button"
							onClick={updateStatus}
							disabled={isPending}
						>
							{isPending ? (
								<Loader size={18} />
							) : checkedIn ? (
								"Undo Check-in "
							) : (
								"Check-in"
							)}
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CheckInModal;
