import { useEffect, useState } from "react";
import FormLayout from "./components/FormLayout";
import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import TimeSlot from "./components/TimeSlot";
import { Calendar } from "@/src/components/ui/calendar";
import {
	useCompleteReservation,
	useGetServiceAvailableTimeSlots,
	useGetServiceDetails,
	useGetStationBasedTimeslots,
	useReserveTimeslot,
} from "@/src/store/slices/scheduleSlice";
import { useFormContext } from "./helpers/ScheduleFormContext";
import { cn } from "@/src/utils/general";
import Loader from "@/src/components/Loader/Loader";
import moment from "moment";
import CustomSelect from "@/src/components/common/CustomSelect";
import { useParams } from "react-router";
import ProviderSelection from "./components/ProviderSelection";
import { AxiosResponse } from "axios";
import useCustomToast from "@/src/components/CustomToast";

const BookTime = () => {
	const { setStep, selectedService } = useScheduleStore();
	const toast = useCustomToast();
	const { scheduling_code } = useParams();
	const {
		appointmentDetails,
		setSelectedStation,
		setScheduleCode,
		setTemporarySlotToken,
		validationDetails,
		validationCode,
		setFormsData,
		reset,
		setSubmissionDetails,
	} = useScheduleStore();
	const { formData, setFormData } = useFormContext();
	const [waitlistChecked, setWaitlistChecked] = useState<boolean>(false);
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		formData?.appointmentDetails?.date
			? new Date(formData?.appointmentDetails?.date)
			: new Date()
	);
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	const [selectedAppointmentMethod, setSelectedAppointmentMethod] = useState<{
		label: string;
		value: string;
	} | null>(null);
	const [selectedProvider, setSelectedProvider] = useState<{
		label: string;
		value: number;
		schedule_code: string;
	} | null>(null);

	const [providerSelectionType, setProviderSelectionType] = useState<
		"datetime" | "provider" | null
	>(null);
	const formattedDate = selectedDate
		? moment(selectedDate).startOf("day").format("YYYY-MM-DD")
		: undefined;

	const { data: serviceData, isLoading: isServiceDataLoading } =
		useGetServiceDetails(selectedService.id, {
			location_id: appointmentDetails?.id,
			date: formattedDate,
			stationId: selectedProvider?.value,
			...((validationDetails?.length || validationCode !== "") && {
				customer_verification_token: validationCode,
			}),
		});

	const {
		data: availableProviders,
		isLoading: isAvailableProvidersLoading,
		error,
	} = useGetStationBasedTimeslots(
		selectedService?.id,
		{
			scheduling_code: scheduling_code ? scheduling_code : "",
			location_id: appointmentDetails?.id
				? appointmentDetails?.id
				: undefined,
			...(selectedDate && {
				date: formattedDate,
			}),
			...((validationDetails?.length || validationCode !== "") && {
				customer_verification_token: validationCode,
			}),
			start_time: formData?.appointmentDetails?.timeslot
				? formData?.appointmentDetails?.timeslot?.slice(0, -3)
				: "",
		},
		formData?.appointmentDetails?.timeslot != null
	);

	const {
		data: timeslotsData,
		isLoading: isTimeSlotsLoading,
		error: serviceTimeSlotsError,
	} = useGetServiceAvailableTimeSlots({
		// appointment_type_id: selectedAppointmentType?.value,
		...((validationDetails?.length || validationCode != "") && {
			customer_verification_token: validationCode,
		}),
		date: formattedDate,
		locationId: appointmentDetails?.id,
		serviceId: selectedService ? parseInt(selectedService?.id) : undefined,
		stationId: selectedProvider?.value,
	});

	const { mutate: reserveTimeslot, isPending: isReserveTimeslotPending } =
		useReserveTimeslot();

	const {
		mutate: completeReservation,
		isPending: isCompleteReservationPending,
	} = useCompleteReservation({
		onSuccess: () => {
			return;
		},
	});

	const availableStations = availableProviders?.data;

	const timeslots = serviceData?.data?.time_slots || timeslotsData?.data;

	const handleAppointmentMethodChange = (selectedOption: any) => {
		setSelectedAppointmentMethod(selectedOption);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				appointmentMethodId: selectedOption?.value,
			},
		}));
	};

	const handleProviderChange = (selectedOption: {
		label: string;
		value: number;
		schedule_code: string;
	}) => {
		//select
		setSelectedProvider(selectedOption);
		setSelectedStation(selectedOption as any);
		if (providerSelectionType == null) {
			setProviderSelectionType("provider");
		}
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				provider: selectedOption?.value,
			},
		}));
	};

	const handleDateChange = (date: Date | undefined) => {
		if (!date) return;
		if (providerSelectionType == null && selectedTime) {
			setProviderSelectionType("datetime");
		}

		const formattedDate = moment(date).startOf("day").format("YYYY-MM-DD");
		setSelectedDate(date);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	};

	const handleTimeSlotChange = (time: string) => {
		setSelectedTime(time);
		if (providerSelectionType == null) {
			setProviderSelectionType("datetime");
		}
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				timeslot: time,
			},
		}));
	};

	const handleBookAppointment = (e: React.FormEvent) => {
		e.preventDefault();
		const data = {
			schedule_code: selectedProvider?.schedule_code as string,
			is_on_notification_waitlist: waitlistChecked,
			service_id: selectedService.id,
			...(formData.appointmentDetails.appointmentMethodId !== null && {
				appointment_method_id:
					formData.appointmentDetails.appointmentMethodId,
			}),
			date: formattedDate ? formattedDate : "",
			start_time: formData.appointmentDetails.timeslot.slice(0, -3),
			...((validationDetails?.length || validationCode !== "") && {
				customer_verification_token: validationCode,
			}),
		};

		reserveTimeslot(
			{
				scheduling_code: selectedProvider?.schedule_code as string,
				data,
			},
			{
				onSuccess: (data: AxiosResponse) => {
					setScheduleCode(
						data?.data?.schedule_code ||
							selectedProvider?.schedule_code
					);
					setFormsData(data?.data?.form);
					setTemporarySlotToken(data?.data?.token || undefined);
					if (
						data?.data?.form === null &&
						data?.data?.custom_intakes?.length < 1
					) {
						handleSubmit(data?.data?.token);
					} else {
						setStep(3);
					}
				},
			}
		);
	};

	const handleSubmit = (temporarySlotToken) => {
		completeReservation(
			{
				slot_token: temporarySlotToken as string,
				data: {
					...((!validationDetails?.length ||
						validationCode == "") && {
						full_name: formData?.patientDetails?.full_name,
						phone_number: formData?.patientDetails?.phone_number,
						email: formData?.patientDetails?.email,
					}),
				},
			},
			{
				onSuccess: (data: AxiosResponse) => {
					reset();
					setSubmissionDetails(data.data);
					toast("Customer scheduled successfully", {
						type: "success",
						id: "schedule-submission",
					});
					setStep(4);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message || "An error occured";
					toast(errorMessage, {
						type: "error",
						id: "schedule-submission",
					});
				},
			}
		);
	};

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	}, []);

	useEffect(() => {
		setSelectedTime(null);
	}, [formattedDate]);

	return (
		<FormLayout>
			<div className="bg-primary-3 flex flex-col gap-y-4 px-6 pb-12 pt-6 font-hoves md:h-[100svh] md:overflow-auto lg:h-auto lg:px-12 2xl:h-screen">
				<div className="mx-auto h-full w-full flex-col items-center gap-6 md:flex  md:flex-row md:items-start">
					<div className="flex w-full flex-col gap-y-3">
						<div className="border-primary-3 flex flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
							<h2 className="text-[22px] font-bold text-main-1">
								Your Service
							</h2>
							<div className="flex items-center gap-x-2">
								<i className="mgc_store_line before:!text-[#043B6D]" />
								<p className="text-sm font-semibold text-main-1">
									{selectedService?.name}
								</p>
							</div>
							{formData?.appointmentDetails?.date &&
								formData?.appointmentDetails?.timeslot && (
									<>
										<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
											<i className="mgc_schedule_line before:!text-primary" />
											<p className="text-sm font-semibold text-main-1">
												{
													formData?.appointmentDetails
														?.date
												}
											</p>
										</div>
										<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
											<i className="mgc_stopwatch_line before:!text-primary" />
											<p className="text-sm text-[#6D748D]">
												{formData?.appointmentDetails?.timeslot?.slice(
													0,
													-3
												)}
											</p>
										</div>
									</>
								)}
						</div>
						<div className="border-primary-3 flex flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
							<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
								<i className="mgc_building_1_line before:!text-primary" />
								<p className="text-sm font-semibold text-main-1">
									{appointmentDetails?.name}
								</p>
							</div>
							<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
								<i className="mgc_location_line before:!text-primary" />
								<p className="text-sm text-[#6D748D]">
									{appointmentDetails?.address}
								</p>
							</div>
						</div>
						{providerSelectionType !== "datetime" && (
							<div className="flex flex-col gap-y-3">
								<div className=" border-primary-3  flex flex-col gap-y-1 rounded-[12px] bg-white px-4 py-4">
									<p className="font-medium text-main-1">
										Select Provider
									</p>
									<CustomSelect
										className="w-full"
										placeholder="Select Provider"
										options={serviceData?.data?.stations?.map(
											(type) => ({
												value: type.id,
												label: type.name,
												schedule_code:
													type.schedule_code,
											})
										)}
										value={selectedProvider}
										onChange={(selectedOption) => {
											handleProviderChange(
												selectedOption as {
													label: string;
													value: number;
													schedule_code: string;
												}
											);
										}}
									/>
								</div>
								{/* Appointment Type  */}
								<div className=" border-primary-3  flex flex-col gap-y-2 rounded-[12px] bg-white px-4 py-6">
									<p className="font-medium text-main-1">
										Select Appointment Method
									</p>
									<CustomSelect
										className="w-full"
										placeholder="Select Appointment Method"
										options={selectedService?.appointment_methods?.map(
											(type) => ({
												value: type.id,
												label: type.name,
											})
										)}
										isDisabled={
											!selectedService
												?.appointment_methods?.length
										}
										value={selectedAppointmentMethod}
										onChange={handleAppointmentMethodChange}
									/>
								</div>
								{/* Appointment Date  */}
								<div className="w-full  justify-center rounded-md border bg-white px-4 py-2">
									<h5 className="pt-2 font-medium text-main-1 ">
										Select Your Appointment Date
									</h5>
									<div className="flex justify-center pb-4">
										<Calendar
											mode="single"
											className="flex h-full w-full"
											classNames={{
												months: "flex w-full flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 flex-1",
												month: "space-y-4 w-full flex flex-col",
												table: "w-full h-full border-collapse space-y-1",
												head_row: "",
												row: "w-full mt-2",
											}}
											selected={selectedDate}
											onSelect={handleDateChange}
											disabled={{ before: new Date() }}
										/>
									</div>
								</div>
							</div>
						)}
					</div>

					{/* //Wip: Add case of the below disappearing only if there are available providers and also if there is no error in fetching  */}
					{providerSelectionType !== "datetime" && (
						<div className="flex w-full flex-col gap-y-3 pt-3">
							<div className=" h-auto w-full rounded-[12px] bg-white px-6 py-6 font-hoves">
								<p className=" text-base font-medium leading-[30px] -tracking-1% text-main-1">
									Select your Appointment Time
								</p>
								<>
									{(isTimeSlotsLoading ||
										isServiceDataLoading) && (
										<div className="mx-auto my-3 flex h-full w-full items-center justify-center self-center">
											<Loader size={25} />
										</div>
									)}

									{serviceTimeSlotsError && (
										<p className="w-full py-4 text-center">
											Error loading time slots
										</p>
									)}

									{(!isTimeSlotsLoading ||
										!isServiceDataLoading) &&
									!error &&
									timeslots?.length > 0 ? (
										<div className="mt-4 flex flex-wrap justify-start gap-4">
											{timeslots?.map(
												(timeslot: any, i: number) => (
													<TimeSlot
														selected={
															selectedTime ===
																timeslot.start_time ||
															formData
																?.appointmentDetails
																?.timeslot ===
																timeslot.start_time
														}
														onClick={() =>
															handleTimeSlotChange(
																timeslot.start_time
															)
														}
														className="w-[144px]"
														timeslot={timeslot}
														key={i}
													/>
												)
											)}
										</div>
									) : (
										<p
											className={cn("py-4", {
												hidden:
													isTimeSlotsLoading ||
													serviceTimeSlotsError,
											})}
										>
											No timeslots available for this
											date.
										</p>
									)}
									<div className="flex flex-col gap-y-2 pt-4">
										<p className="text-sm font-light">
											Would you like to be added to the
											waitlist?
										</p>
										<div className="flex items-center gap-2 font-light ">
											<input
												type="checkbox"
												className="accent-primary"
												checked={waitlistChecked}
												onChange={() => {
													setWaitlistChecked(
														!waitlistChecked
													);
													setFormData((prevData) => ({
														...prevData,
														appointmentDetails: {
															...prevData.appointmentDetails,
															waitlist_checked:
																!waitlistChecked,
														},
													}));
												}}
											/>
											<p className="text-sm text-[#757575]">
												Yes, if an appointment opens up
												before my selected time, please
												notify me.
											</p>
										</div>
										<Button
											className="mt-6 w-full bg-primary px-6 py-1.5"
											type="button"
											disabled={
												!formData?.appointmentDetails
													?.date ||
												!formData?.appointmentDetails
													?.timeslot ||
												!formData?.appointmentDetails
													?.provider
											}
											onClick={handleBookAppointment}
										>
											{isReserveTimeslotPending ||
											isCompleteReservationPending ? (
												<Loader size={14} />
											) : (
												"Schedule Appointment"
											)}
										</Button>
									</div>
								</>
							</div>
						</div>
					)}

					{formData?.appointmentDetails?.date &&
						formData?.appointmentDetails?.timeslot &&
						providerSelectionType == "datetime" && (
							<div className="mt-4 h-auto w-full rounded-[12px] bg-white px-6 py-6 font-hoves">
								<>
									<p className="text-base font-medium leading-[30px] -tracking-1% text-main-1 md:text-[22px]">
										Select A Service Provider
									</p>
									<p className="text-[#6D748D]">
										List of providers who are available at
										your selected time{" "}
									</p>
									{isAvailableProvidersLoading ? (
										<Loader size={16} />
									) : (
										<ProviderSelection
											setSelectedProvider={(provider) => {
												handleProviderChange({
													label: provider?.name,
													value: provider?.id,
													schedule_code:
														provider?.schedule_code,
												});
											}}
											stations={availableStations}
										/>
									)}
									<div className="flex flex-col gap-y-2 pt-4">
										<p className="text-sm font-light">
											Would you like to be added to the
											waitlist?
										</p>
										<div className="flex items-center gap-2 font-light ">
											<input
												type="checkbox"
												className="accent-primary"
												checked={waitlistChecked}
												onChange={() => {
													setWaitlistChecked(
														!waitlistChecked
													);
													setFormData((prevData) => ({
														...prevData,
														appointmentDetails: {
															...prevData.appointmentDetails,
															waitlist_checked:
																!waitlistChecked,
														},
													}));
												}}
											/>
											<p className="text-sm text-[#757575]">
												Yes, if an appointment opens up
												before my selected time, please
												notify me.
											</p>
										</div>
										<Button
											className="mt-6 w-full bg-[#043B6D] px-6 py-1.5"
											type="button"
											disabled={
												!formData?.appointmentDetails
													?.date ||
												!formData?.appointmentDetails
													?.timeslot ||
												!formData?.appointmentDetails
													?.provider ||
												!selectedProvider?.value
											}
											onClick={handleBookAppointment}
										>
											{isReserveTimeslotPending ||
											isCompleteReservationPending ? (
												<Loader size={14} />
											) : (
												"	Schedule Appointment"
											)}
										</Button>
									</div>
								</>
							</div>
						)}
				</div>
			</div>
		</FormLayout>
	);
};

export default BookTime;
