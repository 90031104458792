import React, { useState } from "react";
import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import {
	useGetScheduleData,
	useGetServiceAvailableTimeSlots,
	useStationAvailableTimeSlots,
	useUpdateAppointment,
} from "@/src/store/slices/scheduleSlice";
import TimeSlot from "./components/TimeSlot";
import { Calendar } from "@/src/components/ui/calendar";
import { useNavigate, useParams } from "react-router";
import { formatTimeFrom24HourToMeridian } from "@/src/utils/date";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import Loader from "@/src/components/Loader/Loader";
import useCustomToast from "@/src/components/CustomToast";
import { cn } from "@/src/utils/general";
import moment from "moment";

const RescheduleAppointment: React.FC = () => {
	const toast = useCustomToast();
	const navigate = useNavigate();
	const { appointment_code } = useParams();
	const { data, isLoading } = useGetScheduleData(
		{
			scheduling_code: appointment_code,
		},
		true
	);
	const scheduleData = data?.data;
	// update this
	const { appointmentStationDetails } = useScheduleStore();
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		data?.data?.appointment_date
			? new Date(data.data.appointment_date)
			: undefined
	);
	// const [selectedAppointmentType, setSelectedAppointmentType] =
	// 	useState<any>(null);
	const [selectedTime, setSelectedTime] = useState<string>("");
	const formattedDate = selectedDate
		? moment(selectedDate).startOf("day").format("YYYY-MM-DD")
		: undefined;

	// const {
	// 	data: timeslotsData,
	// 	isLoading: isTimeSlotsLoading,
	// 	error,
	// } = useStationAvailableTimeSlots(scheduleData?.station?.id, {
	// 	date: moment(selectedDate).startOf("day").format("YYYY-MM-DD"),
	// });

	const {
		data: timeslotsData,
		isLoading: isTimeSlotsLoading,
		error,
	} = useGetServiceAvailableTimeSlots({
		// appointment_type_id: selectedAppointmentType?.value,
		date: moment(selectedDate).startOf("day").format("YYYY-MM-DD"),
		// serviceId: selectedService ? parseInt(selectedService) : undefined,
		stationId: scheduleData?.station?.id,
	});

	const { mutate: updateAppointment, isPending: isSubmissionPending } =
		useUpdateAppointment();

	const timeslots =
		timeslotsData?.data || appointmentStationDetails?.time_slots;

	// const handleAppointmentTypeChange = (selectedOption: any) => {
	// 	setSelectedAppointmentType(selectedOption);
	// };

	const handleDateChange = (date: Date | undefined) => {
		if (!date) return;
		setSelectedDate(date);
	};

	const handleTimeSlotChange = (time: string) => {
		setSelectedTime(time);
	};
	const handleRescheduleAppointment = () => {
		updateAppointment(
			{
				appointmentId: appointment_code!,
				data: {
					reschedule_date: formattedDate,
					action: "reschedule",
					reschedule_time: selectedTime?.slice(0, -3),
				},
			},
			{
				onError: (error: any) => {
					toast(
						`${error?.response?.data?.message ?? "Appointment couldn't be rescheduled, try again"}`,
						{
							id: "reschedule-error",
							type: "error",
						}
					);
				},
				onSuccess: () => {
					toast("Appointment Rescheduled Successfully", {
						id: "reschedule-success",
						type: "success",
					});
					setTimeout(() => {
						navigate(`/schedule/${appointment_code}/status`);
					}, 2000);
				},
			}
		);
	};

	// todo: Add a get services endpoint
	return (
		<div className="flex flex-col gap-y-6">
			<div className="flex w-full justify-center border-b-2 border-b-primary bg-white font-hoves">
				<h1 className="py-4 font-semibold text-primary ">
					Reschedule Appointment
				</h1>
			</div>
			<div className="bg-primary-3 flex flex-col gap-y-4 px-6 py-12 font-hoves md:h-screen md:overflow-hidden lg:h-auto lg:px-24 2xl:h-screen">
				<div>
					<div className="h-full w-full justify-center gap-6 align-top md:flex">
						{/* <DateCalendar /> */}
						<div className="flex flex-col gap-y-3 ">
							<div className="border-primary-3 flex flex-col gap-y-2 rounded-[12px] bg-white px-6 pb-6 pt-4">
								<div className="flex items-center gap-2 text-start md:items-start lg:gap-x-2">
									<i className="mgc_building_1_line before:!text-primary" />
									<p className="text-sm font-semibold text-main-1">
										{scheduleData?.station?.location?.name}
									</p>
								</div>
								<div className="flex items-center gap-2 text-start  md:items-start lg:gap-x-2">
									<i className="mgc_location_line before:!text-primary" />
									<p className="text-sm text-[#6D748D]">
										{
											scheduleData?.station?.location
												?.address
										}
									</p>
								</div>
								<div className="flex items-center gap-x-2">
									<i className="mgc_store_line before:!text-primary" />
									<p className="text-sm text-main-1">
										{scheduleData?.station?.name}
									</p>
								</div>
							</div>
							{/* Appointment is not available for this test */}
							{/* <div className=" border-primary-3 my-3 flex flex-col gap-y-1 rounded-[12px] bg-white px-4 py-4">
								<p className="font-medium text-main-1">
									Select Appointment Type
								</p>
								<Select
									className="w-full"
									placeholder="Select Appointment Type"
									options={appointmentStationDetails?.appointment_types?.map(
										(type) => ({
											value: type.id,
											label: type.title,
										})
									)}
									isDisabled={
										!appointmentStationDetails
											?.appointment_types.length
									}
									value={selectedAppointmentType}
									onChange={handleAppointmentTypeChange}
								/>
							</div> */}
							<div className="w-full  justify-center rounded-md border bg-white px-4 py-2">
								<h5 className="font-medium text-main-1 ">
									Select Your Appointment Date
								</h5>
								<div className="flex justify-center ">
									<Calendar
										mode="single"
										className="text-[20px]"
										styles={{
											head_cell: {
												width: "43px",
											},
											cell: {
												width: "43px",
											},
											table: {
												maxWidth: "none",
											},
											day: {
												margin: "auto",
											},
										}}
										selected={selectedDate}
										//add all days to disabled from today backwards
										onSelect={handleDateChange}
									/>
								</div>
							</div>
						</div>

						<div className="w-full pt-4 md:pt-0">
							<div className=" h-auto w-full rounded-[12px] bg-white px-6 py-6 font-hoves">
								<p className="text-base font-medium leading-[30px] -tracking-1% text-main-1 md:text-[22px]">
									Select your New Appointment Time
								</p>
								<p className="text-main-7">
									Previous appointment time:{" "}
									{formatTimeFrom24HourToMeridian(
										data?.data?.appointment_date
											?.split("T")[1]
											?.split(".")[0] ?? "N/A"
									)}
								</p>
								<>
									{isTimeSlotsLoading && (
										<div className="mx-auto my-3 flex h-full w-full items-center justify-center self-center">
											<Loader size={25} />
										</div>
									)}

									{error && <p>Error loading time slots</p>}

									{!isTimeSlotsLoading &&
									!error &&
									timeslots?.length > 1 ? (
										<div className="mt-4 flex flex-wrap justify-evenly gap-4">
											{timeslots?.map(
												(timeslot: any, i: number) => (
													<TimeSlot
														selected={
															selectedTime ===
															timeslot.start_time
														}
														onClick={() =>
															handleTimeSlotChange(
																timeslot.start_time
															)
														}
														className="w-[160px]"
														timeslot={timeslot}
														key={i}
													/>
												)
											)}
										</div>
									) : (
										<p
											className={cn("py-4", {
												hidden: isTimeSlotsLoading,
											})}
										>
											No timeslots available for this
											date.
										</p>
									)}
								</>
							</div>
						</div>
					</div>
				</div>
				<div className="">
					<Button
						className="mt-6 w-full bg-primary px-6 py-1.5"
						type="button"
						onClick={handleRescheduleAppointment}
						disabled={!selectedDate || !selectedTime}
					>
						{isSubmissionPending ? (
							<Loader size={10} />
						) : (
							"Reschedule Appointment"
						)}
					</Button>
				</div>
			</div>
			<RequestIsLoading
				isLoading={isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</div>
	);
};

export default RescheduleAppointment;
