import useScheduleStore from "@/src/store/useScheduleStore";
import { useState } from "react";
import CountdownTimer from "./components/CountdownTimer";
import { Button } from "@/src/components/ui/button";
import { IoClose } from "react-icons/io5";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import CancelTimeslotReservationModal from "./components/CancelTimeslotReservationModal";
import {
	useCompleteReservation,
	useGetSlotTokenInformation,
} from "@/src/store/slices/scheduleSlice";
import { useNavigate, useParams } from "react-router";
import { AxiosResponse, isAxiosError } from "axios";
import { useErrorNotification } from "@/src/hooks/useErrorNotification";
import useCustomToast from "@/src/components/CustomToast";
import IntakeForm from "./IntakeForm";
import { useFormContext } from "./helpers/ScheduleFormContext";
import FormRenderer from "../Forms/components/FormRenderer";

const FillForm = () => {
	const { scheduling_code } = useParams();
	const {
		forms,
		temporarySlotToken,
		validationDetails,
		validationCode,
		reset,
		setSubmissionDetails,
		setStep,
	} = useScheduleStore();
	const { formData } = useFormContext();
	const toast = useCustomToast();
	const navigate = useNavigate();
	const [submissionStep, setSubmissionStep] = useState<
		"intake-form" | "form"
	>("intake-form");
	const [showCancelReservationModal, setShowCancelReservationModal] =
		useState(false);

	const {
		data: tokenInformation,
		error: tokenRetrievalError,
		isSuccess: isTokenRetrievalSuccess,
		isError: isTokenRetrievalError,
		isLoading: isTokenInformationLoading,
	} = useGetSlotTokenInformation(temporarySlotToken!);

	const errorMessage = isAxiosError(tokenRetrievalError)
		? tokenRetrievalError.response?.data?.message ||
			tokenRetrievalError.message
		: "An unknown error occurred";

	useErrorNotification({
		isError: isTokenRetrievalError,
		message: errorMessage,
		id: "token-error",
		duration: 3000,
		action: () => {
			navigate(-1); //todo: update to navigate to home page
			reset();
		},
	});

	const handleNextSubmissionStep = () => {
		if (forms !== null) {
			return setSubmissionStep("form");
		}
		handleSubmit();
	};

	const {
		mutate: completeReservation,
		isPending: isCompleteReservationPending,
		isSuccess: isCompleteReservationSuccess,
	} = useCompleteReservation({
		onSuccess: () => {
			return;
		},
	});

	const handleSubmit = (formResponses?) => {
		if (!scheduling_code) {
			toast("Appointment code is missing.", {
				type: "error",
				id: "missing-appointment-code",
			});
			return;
		}
		if (!validationDetails?.length && !validationCode) {
			if (Object.keys(formData).length < 2) {
				return navigate(`/schedule/${scheduling_code}`);
			}
		}

		completeReservation(
			{
				slot_token: temporarySlotToken as string,
				data: {
					...(validationDetails?.length
						? {
								customer_verification_token: validationCode,
							}
						: {
								full_name: formData?.patientDetails?.full_name,
								phone_number:
									formData?.patientDetails?.phone_number,
								email: formData?.patientDetails?.email,
							}),

					custom_intakes: formData?.custom_intakes,
					...(formResponses && { form_response: formResponses }),
				},
			},
			{
				onSuccess: (data: AxiosResponse) => {
					reset();
					setSubmissionDetails(data.data);
					toast("Customer scheduled successfully", {
						type: "success",
						id: "schedule-submission",
					});
					setStep(4);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message || "An error occured";
					toast(errorMessage, {
						type: "error",
						id: "schedule-submission",
					});
				},
			}
		);
	};

	return (
		<>
			<div className="flex min-h-[100svh] flex-col bg-[#E5E5E7]">
				<div className="flex w-full items-center border-b-2 border-b-primary bg-white font-hoves ">
					<h1 className="mx-auto w-full py-4 text-center font-semibold text-primary ">
						Intake Form
					</h1>
					<button
						className="ml-auto w-fit pr-4"
						onClick={() => navigate(-1)}
					>
						<IoClose className="text-lg" />
					</button>
				</div>

				<div className="mx-auto flex w-full max-w-lg  flex-col items-center justify-between gap-x-4 gap-y-3 px-4 py-4 sm:flex-row ">
					<CountdownTimer />
					<Button
						className="bg-red-500 hover:bg-red-600"
						type="button"
						onClick={() => setShowCancelReservationModal(true)}
					>
						Cancel Reservation
					</Button>
				</div>
				<div>
					{submissionStep == "intake-form" && (
						//todo: add isTokenInformationLoading arg to check and notify when token is expired
						<IntakeForm
							handleSubmission={handleNextSubmissionStep}
							isCompleteReservationPending={
								isCompleteReservationPending
							}
							tokenInformation={tokenInformation}
							isTokenRetrievalSuccess={isTokenRetrievalSuccess}
						/>
					)}
					{submissionStep == "form" && (
						<>
							<RequestIsLoading
								isLoading={isTokenInformationLoading}
							/>
							<div className="px-4">
								<FormRenderer
									data={forms}
									onSubmit={handleSubmit}
									isSubmissionLoading={
										isCompleteReservationPending
									}
									isSubmissionSuccessful={
										isCompleteReservationSuccess
									}
								/>
							</div>
						</>
					)}
				</div>
			</div>

			<RequestIsLoading
				isLoading={isTokenInformationLoading}
				isWhite
				isFullpage
				size={24}
			/>
			<CancelTimeslotReservationModal
				isOpen={showCancelReservationModal}
				setIsOpen={setShowCancelReservationModal}
			/>
		</>
	);
};

export default FillForm;
