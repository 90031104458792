import { ScheduleFormProvider } from "./helpers/ScheduleFormContext";
import BookTime from "./BookTime";
import AppointmentConfirmation from "./AppointmentConfirmation";
import useScheduleStore from "@/src/store/useScheduleStore";
import ValidationForm from "./ValidationForm";
import ServiceSelection from "./ServiceSelection";
import BookProviderTime from "./BookProviderTime";
// import IntakeForm from "./IntakeForm";
import DetailsForm from "./DetailsForm";
import FillForm from "./FillForm";

const ScheduleAppointment = () => {
	const { step, bookingType, appointmentChoice, validationDetails } =
		useScheduleStore();

	return (
		<>
			<ScheduleFormProvider>
				{step == 0 &&
					(validationDetails?.length &&
					validationDetails !== undefined ? (
						<ValidationForm />
					) : (
						<DetailsForm />
					))}
				{step == 1 && <ServiceSelection />}
				{step == 2 &&
					(bookingType == "station" ||
					appointmentChoice == "provider" ? (
						<BookProviderTime />
					) : (
						<BookTime />
					))}
				{step == 3 && <FillForm />}
				{step == 4 && <AppointmentConfirmation />}
			</ScheduleFormProvider>
		</>
	);
};

export default ScheduleAppointment;
