import React from "react";
import welcomeImage from "@/public/assets/appointment-icon.svg";
import CustomInput from "../../components/Waitlist/CustomInput";
import { Button } from "@/src/components/ui/button";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormContext } from "./helpers/ScheduleFormContext";
import useScheduleStore from "@/src/store/useScheduleStore";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import { countryCodes } from "@/src/utils/general";
import { Input } from "@/src/components/ui/input";
import { Label } from "@/src/components/ui/label";
import { useNavigate, useParams } from "react-router";
import Loader from "@/src/components/Loader/Loader";
import { useVerifyCustomer } from "@/src/store/slices/scheduleSlice";
import useCustomToast from "@/src/components/CustomToast";
import { ScheduleInfoData } from "@/src/types/schedule";
import CustomAppointmentSvg from "./components/svgs/CustomAppointmentSvg";

const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	phone: z
		.string()
		.min(10, { message: "Phone number must be at least 11 characters" }),
	email: z
		.string()
		.email({ message: "Invalid email address format" })
		.nullable(),
	agreeToTerms: z.boolean().refine((val) => val === true, {
		message: "You must agree to the terms and conditions",
	}),
});
type FormFields = z.infer<typeof schema>;

const DetailsForm = () => {
	const navigate = useNavigate();
	const { scheduling_code } = useParams();
	const { setStep, bookingType, setAppointmentDetails, setValidationCode } =
		useScheduleStore();
	const toast = useCustomToast();
	const { setFormData } = useFormContext();
	const [countryCode, setCountryCode] = React.useState("+1");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormFields>({
		resolver: zodResolver(schema),
		defaultValues: {
			agreeToTerms: false,
		},
	});

	const onSubmit: SubmitHandler<FormFields> = async (data) => {
		setFormData((prev) => ({
			...prev,
			patientDetails: {
				full_name: data.name,
				phone_number: countryCode + data.phone,
				email: data.email,
			},
		}));
		mutateVerifyCustomer(
			{
				schedule_code: scheduling_code as string,
				data: {
					full_name: data.name,
					phone_number: countryCode + data.phone,
					email: data.email,
				},
			},
			{
				onSuccess: (data) => {
					setAppointmentDetails(
						data?.data as unknown as ScheduleInfoData
					);
					if (data?.customer_verification_token != null) {
						setValidationCode(
							data?.customer_verification_token as string
						);
					}

					if (bookingType === "station") {
						setStep(2);
					} else {
						setStep(1);
					}
				},
				onError: (error) => {
					const errorMessage =
						(error?.response?.data as { message: string })
							?.message || "User is not authorized";

					toast(errorMessage, {
						type: "error",
						id: "validation-error",
						duration: 3000,
					});
				},
			}
		);
	};

	const { mutate: mutateVerifyCustomer, isPending } = useVerifyCustomer({});

	return (
		<div className="flex flex-col gap-y-4">
			<div className=" flex w-full justify-between bg-white font-hoves">
				<div className="flex flex-1 items-center border-b-2 border-[#053969] py-5 lg:px-12">
					<button onClick={() => navigate(-1)}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</button>

					<p className="w-full justify-center text-center text-[15px] font-semibold text-primary md:text-lg">
						Patient Information
					</p>

					<button onClick={() => navigate(-1)}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18 6L6 18M18 18L6 6.00001"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</button>
				</div>
			</div>

			<div className="bg-primary-3 h-auto overflow-auto py-8 font-hoves md:h-screen lg:py-0 ">
				<div className="h-full flex-col items-center gap-y-6 px-4 md:flex md:gap-x-12 lg:flex-row lg:justify-between lg:gap-x-24 lg:px-24">
					<div className=" hidden w-full items-center justify-center md:flex lg:h-full">
						{/* <img
							className=" hidden w-[40%] md:block md:w-[50%] lg:w-[75%]"
							src={welcomeImage}
							alt=""
						/> */}
						<CustomAppointmentSvg
							className="h-[150px] w-[200px] md:h-[200px] md:w-[300px] lg:h-[300px] lg:w-[400px]"
							width={100}
							height={100}
						/>
						{/* <CustomAppointmentSvg/> */}
					</div>
					<div className="flex w-full justify-center">
						<div className=" h-auto rounded-[12px] bg-white px-6 pb-4 pt-10 text-main-4 sm:w-[441px] md:w-full md:max-w-[80%] lg:max-w-full lg:py-4">
							<p className="mt-7 text-[22px] text-lg font-semibold -tracking-1% text-main-1 md:mt-3 lg:leading-[30px]">
								Add your Information
							</p>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="mt-8 lg:mt-4">
									<CustomInput
										label="Full Name"
										error={errors.name?.message || ""}
										register={{ ...register("name") }}
										required
										input
									/>
								</div>
								<div className="mt-2.5">
									<div className="space-y-1.5">
										<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
											Phone Number{" "}
											<span className="text-destructive">
												{" "}
												*
											</span>
										</Label>
										<div className="flex h-10 items-stretch">
											<Select
												value={countryCode}
												onValueChange={(value) => {
													setCountryCode(value);
												}}
											>
												<SelectTrigger className="h-full w-fit  rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
													<SelectValue
														className="w-[40px] px-0 text-[#858C95]"
														placeholder="+1"
													/>
												</SelectTrigger>
												<SelectContent className="!z-[9999]">
													<SelectGroup>
														<SelectLabel className="px-1">
															Country Codes
														</SelectLabel>

														{countryCodes.map(
															(option) => {
																return (
																	<SelectItem
																		key={
																			option.value
																		}
																		value={
																			option.value
																		}
																		className="px-8"
																	>
																		{
																			option.value
																		}
																	</SelectItem>
																);
															}
														)}
													</SelectGroup>
												</SelectContent>
											</Select>
											<Input
												className="h-full rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
												minLength={10}
												{...register("phone")}
												maxLength={11}
											/>
										</div>
										{errors.phone?.message && (
											<small className="text-sm text-destructive">
												{
													errors.phone
														?.message as string
												}
											</small>
										)}
									</div>
								</div>
								<div className="mt-2.5">
									<CustomInput
										label="Email Address"
										error={errors.email?.message || ""}
										register={{ ...register("email") }}
										input
									/>
								</div>

								<div className="flex flex-col pb-2 pt-8">
									<div className="flex flex-col gap-2 font-light ">
										<label className="flex items-center gap-2 text-sm text-[#757575]">
											<input
												type="checkbox"
												className="accent-primary"
												{...register("agreeToTerms")}
											/>
											<p className="ld:text-base font-inter text-sm leading-[22.4px] tracking-0.5% text-main-4 ">
												By confirming, you accept our{" "}
												<a
													className="border-b border-main-1 text-main-1"
													href=""
												>
													Terms
												</a>{" "}
												and{" "}
												<a
													className="border-b border-main-1 text-main-1"
													href=""
												>
													Policies
												</a>
												.
											</p>
										</label>
									</div>
									{errors?.agreeToTerms && (
										<p className="text-sm font-light text-red-500">
											{errors.agreeToTerms?.message}
										</p>
									)}
								</div>
								<div>
									<Button className="w-full bg-primary text-white">
										{isPending ? (
											<Loader size={18} />
										) : (
											"	Continue"
										)}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailsForm;
