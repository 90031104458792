const CustomConfirmedAppointmentSvg = ({
	width,
	height,
	className,
}: {
	width: number;
	height: number;
	className?: string;
}) => {
	const storedTheme = localStorage.getItem("theme");
	const primaryColor = storedTheme || "#25437F";

	// todo: dont forget to update to use width and height, later on after demo --
	return (
		<>
			<svg
				width={width}
				height={height}
				viewBox="0 0 137 118"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
			>
				<g clipPath="url(#clip0_2550_28279)">
					<path
						d="M102.794 18.6108H38.9574C32.8087 18.6108 27.8232 23.6134 27.8232 29.7832V93.8393C27.8232 100.009 32.8087 105.012 38.9574 105.012H102.794C108.943 105.012 113.928 100.009 113.928 93.8393V29.7832C113.928 23.6134 108.943 18.6108 102.794 18.6108Z"
						fill="#E6E9EF"
					/>
					<path
						d="M22.5 40.6777V88.4975C22.5 94.6672 27.4855 99.6698 33.6341 99.6698H97.471C103.62 99.6698 108.605 94.6672 108.605 88.4975V40.6777H22.5Z"
						fill="white"
					/>
					<path
						d="M108.605 24.4414C108.605 18.2716 103.62 13.269 97.471 13.269H33.6341C27.4855 13.269 22.5 18.2716 22.5 24.4414V40.6751H108.605V24.4414Z"
						fill={primaryColor}
					/>
					<path
						d="M41.2443 52.5874H35.7319C35.0483 52.5874 34.4941 53.1435 34.4941 53.8294V59.3608C34.4941 60.0467 35.0483 60.6028 35.7319 60.6028H41.2443C41.9279 60.6028 42.4821 60.0467 42.4821 59.3608V53.8294C42.4821 53.1435 41.9279 52.5874 41.2443 52.5874Z"
						fill={primaryColor}
					/>
					<path
						d="M54.7766 52.5874H49.2641C48.5805 52.5874 48.0264 53.1435 48.0264 53.8294V59.3608C48.0264 60.0467 48.5805 60.6028 49.2641 60.6028H54.7766C55.4601 60.6028 56.0143 60.0467 56.0143 59.3608V53.8294C56.0143 53.1435 55.4601 52.5874 54.7766 52.5874Z"
						fill="#E0E8F3"
					/>
					<path
						d="M68.3088 52.5874H62.7963C62.1127 52.5874 61.5586 53.1435 61.5586 53.8294V59.3608C61.5586 60.0467 62.1127 60.6028 62.7963 60.6028H68.3088C68.9924 60.6028 69.5465 60.0467 69.5465 59.3608V53.8294C69.5465 53.1435 68.9924 52.5874 68.3088 52.5874Z"
						fill="#E0E8F3"
					/>
					<path
						d="M81.841 52.5874H76.3285C75.645 52.5874 75.0908 53.1435 75.0908 53.8294V59.3608C75.0908 60.0467 75.645 60.6028 76.3285 60.6028H81.841C82.5246 60.6028 83.0787 60.0467 83.0787 59.3608V53.8294C83.0787 53.1435 82.5246 52.5874 81.841 52.5874Z"
						fill="#E0E8F3"
					/>
					<path
						d="M95.3742 52.5874H89.8617C89.1782 52.5874 88.624 53.1435 88.624 53.8294V59.3608C88.624 60.0467 89.1782 60.6028 89.8617 60.6028H95.3742C96.0578 60.6028 96.6119 60.0467 96.6119 59.3608V53.8294C96.6119 53.1435 96.0578 52.5874 95.3742 52.5874Z"
						fill="#E0E8F3"
					/>
					<path
						d="M41.2443 66.166H35.7319C35.0483 66.166 34.4941 66.7221 34.4941 67.408V72.9394C34.4941 73.6253 35.0483 74.1814 35.7319 74.1814H41.2443C41.9279 74.1814 42.4821 73.6253 42.4821 72.9394V67.408C42.4821 66.7221 41.9279 66.166 41.2443 66.166Z"
						fill="#E0E8F3"
					/>
					<path
						d="M54.7766 66.166H49.2641C48.5805 66.166 48.0264 66.7221 48.0264 67.408V72.9394C48.0264 73.6253 48.5805 74.1814 49.2641 74.1814H54.7766C55.4601 74.1814 56.0143 73.6253 56.0143 72.9394V67.408C56.0143 66.7221 55.4601 66.166 54.7766 66.166Z"
						fill="#E0E8F3"
					/>
					<path
						d="M68.3088 66.166H62.7963C62.1127 66.166 61.5586 66.7221 61.5586 67.408V72.9394C61.5586 73.6253 62.1127 74.1814 62.7963 74.1814H68.3088C68.9924 74.1814 69.5465 73.6253 69.5465 72.9394V67.408C69.5465 66.7221 68.9924 66.166 68.3088 66.166Z"
						fill="#E0E8F3"
					/>
					<path
						d="M81.841 66.166H76.3285C75.645 66.166 75.0908 66.7221 75.0908 67.408V72.9394C75.0908 73.6253 75.645 74.1814 76.3285 74.1814H81.841C82.5246 74.1814 83.0787 73.6253 83.0787 72.9394V67.408C83.0787 66.7221 82.5246 66.166 81.841 66.166Z"
						fill="#E0E8F3"
					/>
					<path
						d="M95.3742 79.7451H89.8617C89.1782 79.7451 88.624 80.3012 88.624 80.9871V86.5185C88.624 87.2044 89.1782 87.7605 89.8617 87.7605H95.3742C96.0578 87.7605 96.6119 87.2044 96.6119 86.5185V80.9871C96.6119 80.3012 96.0578 79.7451 95.3742 79.7451Z"
						fill="#FF9142"
					/>
					<path
						d="M41.2443 79.7451H35.7319C35.0483 79.7451 34.4941 80.3012 34.4941 80.9871V86.5185C34.4941 87.2044 35.0483 87.7605 35.7319 87.7605H41.2443C41.9279 87.7605 42.4821 87.2044 42.4821 86.5185V80.9871C42.4821 80.3012 41.9279 79.7451 41.2443 79.7451Z"
						fill="#E0E8F3"
					/>
					<path
						d="M54.7766 79.7451H49.2641C48.5805 79.7451 48.0264 80.3012 48.0264 80.9871V86.5185C48.0264 87.2044 48.5805 87.7605 49.2641 87.7605H54.7766C55.4601 87.7605 56.0143 87.2044 56.0143 86.5185V80.9871C56.0143 80.3012 55.4601 79.7451 54.7766 79.7451Z"
						fill={primaryColor}
					/>
					<path
						d="M68.3088 79.7451H62.7963C62.1127 79.7451 61.5586 80.3012 61.5586 80.9871V86.5185C61.5586 87.2044 62.1127 87.7605 62.7963 87.7605H68.3088C68.9924 87.7605 69.5465 87.2044 69.5465 86.5185V80.9871C69.5465 80.3012 68.9924 79.7451 68.3088 79.7451Z"
						fill="#E0E8F3"
					/>
					<path
						d="M81.841 79.7451H76.3285C75.645 79.7451 75.0908 80.3012 75.0908 80.9871V86.5185C75.0908 87.2044 75.645 87.7605 76.3285 87.7605H81.841C82.5246 87.7605 83.0787 87.2044 83.0787 86.5185V80.9871C83.0787 80.3012 82.5246 79.7451 81.841 79.7451Z"
						fill="#D9294A"
					/>
					<path
						d="M95.3742 66.166H89.8617C89.1782 66.166 88.624 66.7221 88.624 67.408V72.9394C88.624 73.6253 89.1782 74.1814 89.8617 74.1814H95.3742C96.0578 74.1814 96.6119 73.6253 96.6119 72.9394V67.408C96.6119 66.7221 96.0578 66.166 95.3742 66.166Z"
						fill="#E0E8F3"
					/>
					<g style={{ mixBlendMode: "multiply" }}>
						<path
							d="M39.2666 23.0306C41.0879 22.5992 42.2159 20.768 41.7861 18.9404C41.3562 17.1129 39.5312 15.981 37.7099 16.4123C35.8886 16.8437 34.7606 18.6749 35.1905 20.5025C35.6203 22.3301 37.4453 23.4619 39.2666 23.0306Z"
							fill="#9EABC2"
						/>
					</g>
					<g style={{ mixBlendMode: "multiply" }}>
						<path
							d="M52.7969 23.0316C54.6182 22.6002 55.7462 20.769 55.3163 18.9414C54.8865 17.1138 53.0615 15.982 51.2402 16.4133C49.4189 16.8447 48.2909 18.6759 48.7207 20.5035C49.1506 22.331 50.9756 23.4629 52.7969 23.0316Z"
							fill="#9EABC2"
						/>
					</g>
					<g style={{ mixBlendMode: "multiply" }}>
						<path
							d="M66.3291 23.0321C68.1504 22.6007 69.2784 20.7695 68.8486 18.9419C68.4187 17.1143 66.5937 15.9824 64.7724 16.4138C62.9511 16.8451 61.8231 18.6764 62.253 20.504C62.6828 22.3315 64.5078 23.4634 66.3291 23.0321Z"
							fill="#9EABC2"
						/>
					</g>
					<g style={{ mixBlendMode: "multiply" }}>
						<path
							d="M79.8613 23.0325C81.6827 22.6012 82.8107 20.77 82.3808 18.9424C81.9509 17.1148 80.126 15.9829 78.3046 16.4143C76.4833 16.8456 75.3553 18.6769 75.7852 20.5044C76.2151 22.332 78.04 23.4639 79.8613 23.0325Z"
							fill="#9EABC2"
						/>
					</g>
					<g style={{ mixBlendMode: "multiply" }}>
						<path
							d="M93.3936 23.033C95.2149 22.6017 96.3429 20.7705 95.913 18.9429C95.4831 17.1153 93.6582 15.9834 91.8369 16.4148C90.0155 16.8461 88.8875 18.6773 89.3174 20.5049C89.7473 22.3325 91.5722 23.4644 93.3936 23.033Z"
							fill="#9EABC2"
						/>
					</g>
					<path
						d="M38.4894 9.01172C37.3582 9.01172 36.4346 9.93585 36.4346 11.0736V19.722C36.4346 20.8571 37.3555 21.7839 38.4894 21.7839C39.6234 21.7839 40.5443 20.8598 40.5443 19.722V11.0736C40.5443 9.93852 39.6234 9.01172 38.4894 9.01172Z"
						fill="#8796B2"
					/>
					<path
						d="M52.0187 9.01172C50.8875 9.01172 49.9639 9.93585 49.9639 11.0736V19.722C49.9639 20.8571 50.8848 21.7839 52.0187 21.7839C53.1526 21.7839 54.0736 20.8598 54.0736 19.722V11.0736C54.0736 9.93852 53.1526 9.01172 52.0187 9.01172Z"
						fill="#8796B2"
					/>
					<path
						d="M65.5519 9.01172C64.4207 9.01172 63.4971 9.93585 63.4971 11.0736V19.722C63.4971 20.8571 64.418 21.7839 65.5519 21.7839C66.6859 21.7839 67.6068 20.8598 67.6068 19.722V11.0736C67.6068 9.93852 66.6859 9.01172 65.5519 9.01172Z"
						fill="#8796B2"
					/>
					<path
						d="M79.0842 9.01172C77.9529 9.01172 77.0293 9.93585 77.0293 11.0736V19.722C77.0293 20.8571 77.9503 21.7839 79.0842 21.7839C80.2181 21.7839 81.139 20.8598 81.139 19.722V11.0736C81.139 9.93852 80.2181 9.01172 79.0842 9.01172Z"
						fill="#8796B2"
					/>
					<path
						d="M92.6164 9.01172C91.4852 9.01172 90.5615 9.93585 90.5615 11.0736V19.722C90.5615 20.8571 91.4825 21.7839 92.6164 21.7839C93.7503 21.7839 94.6713 20.8598 94.6713 19.722V11.0736C94.6713 9.93852 93.7503 9.01172 92.6164 9.01172Z"
						fill="#8796B2"
					/>
					<path
						d="M83.7839 73.6914C89.7869 67.6678 89.7869 57.9016 83.7839 51.878C77.7809 45.8543 68.0482 45.8543 62.0452 51.878C56.0422 57.9016 56.0422 67.6678 62.0452 73.6914C68.0482 79.715 77.7809 79.715 83.7839 73.6914Z"
						fill={primaryColor}
					/>
					<path
						d="M58.7603 77.5978L78.6303 72.256L63.4796 57.0532L58.1561 76.9915C58.0576 77.36 58.393 77.6992 58.763 77.6004L58.7603 77.5978Z"
						fill={primaryColor}
					/>
					<path
						d="M81.1492 58.6528L78.7962 56.2944L70.5634 64.5555L67.0339 61.0139L64.6836 63.375L68.2104 66.9166L70.5634 69.275L72.9164 66.9166L81.1492 58.6528Z"
						fill="white"
					/>
				</g>
				<g style={{ mixBlendMode: "darken", opacity: "0.3" }}>
					<path
						d="M110.452 10.9467C109.618 10.4925 109.618 9.76586 110.443 9.32076L115.776 6.42308C116.6 5.97799 117.939 5.9689 118.773 6.42308L124.135 9.32076C124.969 9.76586 124.969 10.5016 124.145 10.9467L118.811 13.8444C117.987 14.2986 116.639 14.2895 115.815 13.8444L110.452 10.9467Z"
						fill={primaryColor}
					/>
					<g opacity="0.29">
						<path
							d="M121.974 17.1689C121.14 16.7238 121.14 15.988 121.964 15.5429L127.298 12.6453C128.122 12.2002 129.47 12.2002 130.294 12.6453L135.657 15.5429C136.49 15.9971 136.49 16.7238 135.666 17.1689L130.333 20.0666C129.509 20.5208 128.17 20.5208 127.336 20.0666L121.974 17.1689Z"
							fill={primaryColor}
						/>
					</g>
					<path
						d="M122.292 27.0883C121.458 26.6341 121.458 25.9075 122.282 25.4624L127.616 22.5647C128.44 22.1105 129.778 22.1105 130.612 22.5647L135.975 25.4624C136.809 25.9075 136.809 26.6432 135.985 27.0883L130.651 29.986C129.827 30.4311 128.479 30.4311 127.655 29.986L122.292 27.0883Z"
						fill={primaryColor}
					/>
					<path
						d="M92.4446 10.9464C91.6106 10.5013 91.6106 9.76556 92.4349 9.32046L97.7683 6.42279C98.5926 5.96861 99.9405 5.97769 100.765 6.42279L106.137 9.32955C106.971 9.77464 106.971 10.5104 106.147 10.9555L100.813 13.8532C99.989 14.2983 98.6411 14.2983 97.8168 13.8532L92.4446 10.9464Z"
						stroke="#E6F4FF"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M121.894 4.86078C121.06 4.4066 121.06 3.67992 121.884 3.23482L127.217 0.337143C128.042 -0.107954 129.38 -0.117038 130.214 0.337143L135.576 3.23482C136.41 3.67992 136.41 4.41569 135.586 4.86078L130.253 7.75846C129.428 8.20356 128.081 8.20356 127.256 7.75846L121.894 4.86078Z"
						fill={primaryColor}
					/>
				</g>
				<g style={{ mixBlendMode: "darken", opacity: "0.3" }}>
					<path
						d="M26.5369 107.053C27.3709 107.507 27.3709 108.234 26.5466 108.679L21.2132 111.577C20.3889 112.022 19.0507 112.031 18.2168 111.577L12.8542 108.679C12.0203 108.234 12.0203 107.498 12.8445 107.053L18.178 104.156C19.0022 103.701 20.3501 103.71 21.1744 104.156L26.5369 107.053Z"
						fill={primaryColor}
					/>
					<g opacity="0.29">
						<path
							d="M15.0262 100.822C15.8601 101.267 15.8601 102.003 15.0359 102.448L9.70244 105.345C8.87818 105.791 7.53028 105.791 6.70602 105.345L1.34348 102.448C0.509525 101.994 0.509525 101.267 1.33378 100.822L6.66723 97.9241C7.49149 97.47 8.8297 97.47 9.66365 97.9241L15.0262 100.822Z"
							fill={primaryColor}
						/>
					</g>
					<path
						d="M14.7078 90.9117C15.5418 91.3659 15.5418 92.0925 14.7175 92.5376L9.38408 95.4353C8.55982 95.8895 7.22161 95.8895 6.38766 95.4353L1.02512 92.5376C0.191166 92.0925 0.191166 91.3568 1.01543 90.9117L6.34887 88.014C7.17313 87.5689 8.52104 87.5689 9.3453 88.014L14.7078 90.9117Z"
						fill={primaryColor}
					/>
					<path
						d="M44.5476 107.053C45.3816 107.498 45.3816 108.234 44.5573 108.679L39.2239 111.577C38.3996 112.031 37.0517 112.022 36.2274 111.577L30.8552 108.67C30.0212 108.225 30.0212 107.489 30.8455 107.044L36.1789 104.146C37.0032 103.701 38.3511 103.701 39.1754 104.146L44.5476 107.053Z"
						stroke="#E1EAF1"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M15.0965 113.139C15.9305 113.593 15.9305 114.32 15.1062 114.765L9.77276 117.663C8.9485 118.108 7.61029 118.117 6.77633 117.663L1.41379 114.765C0.579838 114.32 0.579838 113.584 1.4041 113.139L6.73754 110.242C7.5618 109.796 8.90971 109.796 9.73397 110.242L15.0965 113.139Z"
						fill={primaryColor}
					/>
				</g>
				<defs>
					<clipPath id="clip0_2550_28279">
						<rect
							width="91.4286"
							height="96"
							fill="white"
							transform="translate(22.5 9.01172)"
						/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
};

export default CustomConfirmedAppointmentSvg;
