import { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
	CardFooter,
} from "@/src/components/ui/card";
import { Label } from "@/src/components/ui/label";
import { Input } from "@/src/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/src/components/ui/radio-group";
import { Checkbox } from "@/src/components/ui/checkbox";
import { Button } from "@/src/components/ui/button";
import { IoInformationCircle } from "react-icons/io5";
import { FormSections } from "../../../types/form-renderer";
import Loader from "../../../components/Loader/Loader";
import useCustomToast from "../../../components/CustomToast";
import { DatePicker } from "@/src/components/ui/datepicker";
import DateRangePicker from "@/src/components/ui-extended/date-range-picker";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/src/components/ui/select";
import { DateRange } from "react-day-picker";
import SubmissionBlockModal from "./SubmissionBlockModal";

interface FormRenderProps {
	data: any;
	isSubmissionSuccessful: boolean;
	isSubmissionLoading: boolean;
	onSubmit: (formResponses: any) => void;
}

const FormRenderer = ({
	data,
	isSubmissionSuccessful,
	isSubmissionLoading,
	onSubmit,
}: FormRenderProps) => {
	const [navigationHistory, setNavigationHistory] = useState([0]);
	const [visitedSections, setVisitedSections] = useState(new Set());
	const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
	const [showBlockSubmissionModal, setShowBlockSubmissionModal] =
		useState(false);
	const [formResponses, setFormResponses] = useState({});
	const toast = useCustomToast();

	const sections: FormSections = data?.sections;
	const formTitle = data?.name;
	const submissionMessage = data?.success_message;
	const submissionButtonTitle = data?.success?.submit_button_title;
	const formBanner = data?.banner_url || data?.logo_url;

	const handleInputChange = (fieldId, value) => {
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: value,
		}));
	};

	const handleOptionSelect = (fieldId, selectedOption) => {
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: selectedOption.value,
		}));
	};

	const handleCheckboxChange = (fieldId, option, checked) => {
		setFormResponses((prev) => {
			const currentValues = prev[fieldId] || [];

			let newValues;
			if (checked) {
				newValues = currentValues.includes(option.value)
					? currentValues
					: [...currentValues, option.value];
			} else {
				newValues = currentValues.filter(
					(value) => value !== option.value
				);
			}

			return {
				...prev,
				[fieldId]: newValues,
			};
		});
	};
	const handleDateChange = (fieldId, date) => {
		const formattedDate = date ? new Date(date).toISOString() : null;
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: formattedDate,
		}));
	};

	const handleDateRangeChange = (
		fieldId: string,
		range: DateRange | undefined
	) => {
		if (!range) return;

		const formattedRange = {
			from: range.from ? new Date(range.from).toISOString() : null,
			to: range.to ? new Date(range.to).toISOString() : null,
		};

		setFormResponses((prev) => ({
			...prev,
			[fieldId]: formattedRange,
		}));
	};

	const handleFileChange = (fieldId: string, files: FileList | null) => {
		if (!files || files.length === 0) return;
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: files[0],
		}));
	};

	const handleDropdownChange = (fieldId, selectedOption) => {
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: selectedOption.value,
		}));
	};

	const handleNumberChange = (fieldId, value) => {
		// Ensure it's a number or empty string
		const numericValue = value === "" ? "" : Number(value);
		setFormResponses((prev) => ({
			...prev,
			[fieldId]: numericValue,
		}));
	};

	const processCondition = (condition) => {
		switch (condition.type) {
			case "submit":
				handleSubmit();
				break;
			case "goto": {
				const nextSection = sections.find(
					(section) => section.id === condition.destination
				);
				if (nextSection) {
					const nextIndex = sections.indexOf(nextSection);

					if (visitedSections.has(nextIndex)) {
						toast("Circular navigation detected", {
							type: "error",
							id: "navigation-error",
						});
						return;
					}

					setVisitedSections((prev) => new Set(prev).add(nextIndex));
					setNavigationHistory((prev) => [
						...prev,
						currentSectionIndex,
					]);

					setCurrentSectionIndex(nextIndex);
				}
				if (!nextSection) {
					toast("Invalid section navigation", {
						type: "error",
						id: "form-error",
					});
				}
				break;
			}
			case "continue":
				if (currentSectionIndex < sections.length - 1) {
					setNavigationHistory((prev) => [
						...prev,
						currentSectionIndex,
					]);
					setCurrentSectionIndex(currentSectionIndex + 1);
				}
				break;
			case "block":
				return setShowBlockSubmissionModal(true);
			default:
				console.warn(`Unhandled condition type: ${condition.type}`);
				break;
		}
	};

	const handleSubmit = async () => {
		if (!isFormComplete()) {
			toast("Please complete all required fields", {
				type: "error",
				id: "form-submission",
			});
			return;
		}

		try {
			await onSubmit(formResponses);
		} catch (error) {
			toast("Form Submission Error", {
				id: "form-submission",
				type: "error",
			});
		}
	};

	//todo: this should work properly after update to infoimage and infotext
	const isFormComplete = () => {
		if (sections) {
			for (const section of sections) {
				for (const field of section.fields) {
					if (field.required && !formResponses[field.id]) {
						return false;
					}
				}
			}
		}

		return true;
	};

	useEffect(() => {
		if (isSubmissionSuccessful) {
			setCurrentSectionIndex(sections.length);
			setVisitedSections(new Set());
		}
	}, [isSubmissionSuccessful, sections?.length]);

	const validationRules = {
		longtext: (value, field) => {
			if (field.required && !value) return "This is a required question";
			if (field.minLength && value.length < field.minLength)
				return `Minimum ${field.minLength} characters required`;
			return null;
		},
		text: (value, field) => {
			if (field.required && !value) return "This is a required question";
			if (field.minLength && value.length < field.minLength)
				return `Minimum ${field.minLength} characters required`;
			return null;
		},
		radio: (value, field) => {
			if (field.required && !value) return "Please select an option";
			const validOptions = field.options.map((opt: any) => opt.value);
			if (value && !validOptions.includes(value))
				return "Please select a valid option";
			return null;
		},
		checkbox: (value, field) => {
			if ((field.required && !value) || value.length === 0)
				return "Please select an option";
			return null;
		},
		numeric: (value: number | string, field: any) => {
			if (field.required && (value === "" || value === undefined))
				return "This field is required";
			if (value === "") return null;
			const numValue = Number(value);
			if (field.required && isNaN(numValue))
				return "Please enter a valid number";
			if (field.required && field.min && numValue < field.min)
				return `Minimum value is ${field.min}`;
			if (field.required && field.max && numValue > field.max)
				return `Maximum value is ${field.max}`;
			return null;
		},
		date: (value: string, field: any) => {
			if (field.required && !value) return "This field is required";
			if (value && !Date.parse(value)) return "Please enter a valid date";
			return null;
		},
		date_range: (
			value: { from: string; to: string } | null,
			field: any
		) => {
			if (field.required && (!value || !value.from || !value.to))
				return "Both dates are required";
			if (value) {
				const fromDate = new Date(value.from);
				const toDate = new Date(value.to);
				if (fromDate > toDate)
					return "Start date must be before end date";
			}
			return null;
		},
		dropdown: (value: string, field: any) => {
			if (field.required && !value) return "Please select an option";
			const validOptions = field.options.map((opt: any) => opt.value);
			if (value && !validOptions.includes(value))
				return "Please select a valid option";
			return null;
		},
		attachment: (value: File | null, field: any) => {
			if (field.required && !value) return "Please upload a file";
			if (value) {
				const extension = value.name.split(".").pop()?.toLowerCase();
				const allowedFormats = field.approved_formats.map(
					(format: string) => format.toLowerCase()
				);
				if (!extension || !allowedFormats.includes(extension)) {
					return `Please upload a file in one of these formats: ${field.approved_formats.join(", ")}`;
				}
				if (field.maxSize && value.size > field.maxSize) {
					return `File size must be less than ${field.maxSize / (1024 * 1024)}MB`;
				}
			}
			return null;
		},
	};

	const [errors, setErrors] = useState({});

	const validateSection = (sectionIndex: number) => {
		const newErrors: { [key: string]: string } = {};
		const section = sections[sectionIndex];

		section.fields.forEach((field) => {
			const validator =
				validationRules[field.type as keyof typeof validationRules];
			if (validator) {
				const error = validator(formResponses[field.id], field);
				if (error) newErrors[field.id] = error;
			}
		});

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleNext = () => {
		if (!validateSection(currentSectionIndex)) return;
		const currentSection = sections[currentSectionIndex];

		for (const field of currentSection.fields) {
			if (["radio", "dropdown"].includes(field.type)) {
				const selectedValue = formResponses[field.id];
				const selectedOption =
					field.options &&
					field.options.find((opt) => opt.value === selectedValue);

				if (selectedOption?.conditions) {
					processCondition(selectedOption.conditions);
					return;
				}
			}
		}

		//todo: Default next behavior and also try to consider other conditions
		if (currentSection.flow?.targetSection) {
			const targetIndex = sections.findIndex(
				(s) => s.id === currentSection.flow.targetSection
			);
			if (targetIndex !== -1) {
				setNavigationHistory((prev) => [...prev, currentSectionIndex]);
				setCurrentSectionIndex(targetIndex);
				return;
			}
		}

		if (currentSectionIndex < sections.length - 1) {
			setNavigationHistory((prev) => [...prev, currentSectionIndex]);
			setCurrentSectionIndex(currentSectionIndex + 1);
		} else {
			handleSubmit();
		}
	};

	const handleBack = () => {
		if (navigationHistory.length > 1) {
			const prevHistory = [...navigationHistory];
			const prevIndex = prevHistory.pop() || 0;
			setNavigationHistory(prevHistory);
			setCurrentSectionIndex(prevIndex);
		}
	};

	const renderField = (field) => {
		switch (field.type) {
			//todo: update later to textarea
			case "longtext":
			case "text":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label
							className="text-base font-bold"
							htmlFor={field.id}
						>
							{field.title}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						{/* //todo: add description to it */}
						<Input
							id={field.id}
							value={formResponses[field.id] || ""}
							onChange={(e) =>
								handleInputChange(field.id, e.target.value)
							}
							placeholder="your answer"
							required={field.required}
							className="w-full"
						/>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);
			case "radio":
				return (
					<div
						key={field.id}
						className="flex flex-col justify-start gap-y-3"
					>
						<Label className="text-base font-bold">
							{field.title}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<RadioGroup
							onValueChange={(value) => {
								const selectedOption = field.options.find(
									(opt) => opt.id === value
								);
								if (selectedOption) {
									handleOptionSelect(
										field.id,
										selectedOption
									);
								}
							}}
							className="flex "
							value={
								field.options.find(
									(opt) =>
										opt.value === formResponses[field.id]
								)?.id
							}
						>
							{field.options.map((option) => (
								<div
									key={option.id}
									className="flex items-center gap-x-2"
								>
									<RadioGroupItem
										value={option.id}
										id={option.id}
									/>
									<Label htmlFor={option.id}>
										{option.value}
									</Label>
								</div>
							))}
						</RadioGroup>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);
			case "numeric":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label
							className="text-base font-bold"
							htmlFor={field.id}
						>
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<Input
							id={field.id}
							type="number"
							placeholder="your answer"
							value={formResponses[field.id] || ""}
							onChange={(e) =>
								handleNumberChange(field.id, e.target.value)
							}
							required={field.required}
							className="w-full"
						/>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "date":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<DatePicker
							date={
								formResponses[field.id]
									? new Date(formResponses[field.id])
									: undefined
							}
							setDate={(date) => handleDateChange(field.id, date)}
						/>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "date_range":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<DateRangePicker
							value={{
								from: formResponses[field.id]?.from
									? new Date(formResponses[field.id].from)
									: undefined,
								to: formResponses[field.id]?.to
									? new Date(formResponses[field.id].to)
									: undefined,
							}}
							onChange={(range) =>
								handleDateRangeChange(field.id, range)
							}
						/>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "dropdown":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<Select
							value={formResponses[field.id]}
							onValueChange={(value) => {
								const selectedOption = field.options.find(
									(opt) => opt.value === value
								);
								handleDropdownChange(field.id, selectedOption);
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select an option" />
							</SelectTrigger>
							<SelectContent>
								{field.options.map((option) => (
									<SelectItem
										key={option.id}
										value={option.value}
									>
										{option.value}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			case "checkbox":
				return (
					<div key={field.id} className="flex flex-col gap-y-3">
						<Label className="text-base font-bold">
							{field.title}{" "}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<div className="flex items-center gap-x-2">
							{field.options.map((option) => (
								<div
									key={option.id}
									className="flex items-center gap-x-2"
								>
									<Checkbox
										id={option.id}
										checked={(
											formResponses[field.id] || []
										).includes(option.value)}
										onCheckedChange={(checked) =>
											handleCheckboxChange(
												field.id,
												option,
												checked
											)
										}
									/>
									<Label htmlFor={option.id}>
										{option.value}
									</Label>
								</div>
							))}
						</div>
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);
			case "attachment":
				return (
					<div key={field.id} className="flex flex-col gap-y-2">
						<Label className="text-base font-bold">
							{field.title}
							{field.required && (
								<span className="pl-[2px] text-red-500">*</span>
							)}
						</Label>
						<div key={field.id} className="">
							<Input
								type="file"
								accept={field.approved_formats
									.map((format) => `.${format.toLowerCase()}`)
									.join(",")}
								onChange={(e) =>
									handleFileChange(field.id, e.target.files)
								}
								required={field.required}
							/>
						</div>
						{field.description && (
							<p className="text-sm text-gray-500">
								Accepted formats:{" "}
								{field.approved_formats.join(", ")}
							</p>
						)}
						{errors[field.id] && (
							<div className="mt-1 flex items-center gap-1 text-red-500">
								<IoInformationCircle />
								<p className="text-sm ">{errors[field.id]}</p>
							</div>
						)}
					</div>
				);

			default:
				console.warn(`Field type not handled yet, ${field.type}`);
				return <p>Unsupported field type: {field.type}</p>;
		}
	};

	if (!sections || sections.length === 0) {
		return (
			<div className="flex min-h-screen items-center justify-center">
				<p>No form sections available.</p>
			</div>
		);
	}

	if (currentSectionIndex >= sections?.length) {
		return (
			<>
				<div className="flex min-h-screen flex-col justify-center bg-[#E5EEF4] p-4">
					<div className="mx-auto my-auto w-full max-w-sm">
						<Card className="flex h-[30vh] w-full flex-col items-center justify-center border-t-8 border-t-primary">
							<CardHeader className="text-center">
								<CardTitle className="text-base">
									{submissionMessage ||
										"Thank you for your responses!"}
								</CardTitle>
							</CardHeader>
						</Card>
					</div>
				</div>
			</>
		);
	}

	const currentSection = sections && sections[currentSectionIndex];
	return (
		<>
			{/* <div className="flex min-h-screen flex-col justify-center bg-[#E5EEF4] p-4"> */}
			<div className="mx-auto my-auto w-full max-w-lg ">
				{formBanner && (
					<div className="mb-2 flex h-[150px] w-full flex-col items-center justify-center  rounded-lg bg-white px-6 py-1 md:w-full md:max-w-[80%] lg:max-w-full">
						<img
							src={formBanner}
							className="pointer-events-none h-full w-full object-contain"
							alt="bg-image"
						/>
					</div>
				)}

				<Card className="flex min-h-[80vh] w-full flex-col border-t-8 border-t-primary">
					<div className="mx-6 border-b-2 pb-2 pt-4">
						<p className="text-3xl font-bold">
							{formTitle || "Service Form"}
						</p>
						{/* <p className="text-[#64748B]">
								Take up new information from the patient
							</p> */}
					</div>
					<CardHeader>
						<CardTitle>{currentSection?.title}</CardTitle>
						{currentSection?.description && (
							<CardDescription className="text-[#64748B]">
								{currentSection?.description}
							</CardDescription>
						)}
					</CardHeader>
					<CardContent className="flex flex-col gap-y-6">
						{currentSection?.fields?.map(renderField)}
					</CardContent>
					<CardFooter className="mt-auto flex justify-between gap-x-2">
						<Button
							onClick={handleBack}
							variant="outline"
							disabled={currentSectionIndex === 0}
						>
							Back
						</Button>
						<div className=" h-2 w-full max-w-48 rounded-full bg-gray-200">
							<div
								className="h-2 rounded-full bg-primary transition-all"
								style={{
									width: `${sections?.length ? ((currentSectionIndex + 1) / sections.length) * 100 : 0}%`,
								}}
							/>
						</div>
						<div className="flex items-center gap-x-2">
							<Button
								onClick={handleSubmit}
								variant="outline"
								className="h-10 w-16"
							>
								{isSubmissionLoading ? (
									<span>
										<Loader size={18} />
									</span>
								) : (
									submissionButtonTitle || "Submit"
								)}
							</Button>
							{currentSectionIndex < sections?.length - 1 && (
								<Button onClick={handleNext}>Next</Button>
							)}
						</div>
					</CardFooter>
				</Card>
			</div>

			{showBlockSubmissionModal && (
				<SubmissionBlockModal
					isOpen={showBlockSubmissionModal}
					setIsOpen={setShowBlockSubmissionModal}
				/>
			)}
		</>
	);
};

export default FormRenderer;
