import { useEffect, useState } from "react";
import useScheduleStore from "@/src/store/useScheduleStore";
import { IoSearch } from "react-icons/io5";
import { Label } from "@/src/components/ui/label";
import { ChevronRightIcon } from "@radix-ui/react-icons";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/src/components/ui/tabs";
import { useDebouncedSearch } from "@/src/hooks/useDebouncedSearch";
import { ScheduleLocationData } from "@/src/types/schedule";
import CustomConfirmedAppointmentSvg from "./components/svgs/CustomConfirmedAppointmentSvg";

const ServiceSelection = () => {
	const {
		bookingType,
		setSelectedStation,
		setSelectedService,
		appointmentDetails,
		setAppointmentDetails,
		// setBookingType,
		// setSelectedStationServices,
	} = useScheduleStore();
	const [bookingStep, setBookingStep] = useState<
		"business_services" | "location_services"
	>(bookingType == "business" ? "business_services" : "location_services");
	const { setStep, setAppointmentChoice } = useScheduleStore();
	const [selectedLocation, setSelectedLocation] =
		useState<ScheduleLocationData | null>(null);

	//business flow is pending
	// const handleBusinessSelection = () => {
	// setAppointmentDetails to whatever location was selected
	//setbookingstep to location_services
	// Then the stations and services can collect from there
	// };

	const handleLocationSelection = (location) => {
		setSelectedLocation(location);
		setBookingStep("location_services");
		setAppointmentDetails(location);
	};

	const handleProviderSelection = (provider) => {
		setSelectedStation(provider);
		setAppointmentChoice("provider");
		setStep(2); // open provider date / time selection page
	};
	const handleServiceSelection = (service) => {
		setAppointmentChoice("service");
		setSelectedService(service);
		setStep(2); // open date / time selection page
	};

	// effect for cases of station
	useEffect(() => {
		if (bookingType == "station") {
			setStep(2);
			setSelectedStation(appointmentDetails?.station as unknown as any[]);
		}
	});

	return (
		<div className="bg-primary-3 flex w-full flex-col gap-y-4 px-6 pb-12 pt-6 font-hoves md:h-[100svh] md:overflow-auto lg:h-auto lg:px-12 2xl:h-screen">
			<div className="h-full w-full items-center justify-center gap-6 align-top md:flex md:items-start">
				<div className="border-primary-3 flex w-full flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
					<div className="flex items-center justify-center gap-x-2 rounded-md bg-white py-3 text-lg text-main-2 ">
						<CustomConfirmedAppointmentSvg width={60} height={60} />
						<p className="text-lg font-semibold text-primary">
							Schedule an Appointment
						</p>
					</div>
					{/* for business first , show the below  */}
					{bookingType == "business" &&
					bookingStep == "business_services" ? (
						<div className="mt-6 flex flex-col gap-y-1">
							<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
								<i className="mgc_building_1_line before:!text-primary" />
								<p className="text-sm font-bold text-main-1">
									{appointmentDetails?.name}
								</p>
							</div>

							{/* Might update appointment details here because of the category selection  */}
							{/* Used for location selection, refactor to location service  */}
							<ServiceSection
								services={appointmentDetails?.locations}
								handleClick={handleLocationSelection}
							/>
						</div>
					) : null}

					{/* For station directly or after business, show the next  */}
					{bookingStep == "location_services" ? (
						<div className="flex flex-col gap-y-6 ">
							<div className=" flex flex-col gap-y-2">
								<div className="flex items-center gap-2 text-start md:items-start lg:gap-4">
									<i className="mgc_building_1_line before:!text-primary" />
									<p className="text-sm font-semibold text-main-1">
										{bookingType == "business"
											? selectedLocation?.name
											: appointmentDetails?.name}
									</p>
								</div>
								<div className="flex items-center gap-2 text-start md:items-start lg:gap-4">
									<i className="mgc_location_line before:!text-primary" />
									<p className="text-sm text-[#6D748D]">
										{appointmentDetails?.address}
									</p>
								</div>
							</div>
							<div className="flex flex-col gap-y-4">
								<h2 className="font-bold leading-[140%] tracking-[-0.14px] text-main-1">
									Select a Practioner or Service
								</h2>
								<Tabs
									defaultValue="services"
									className="w-full"
								>
									<TabsList className="grid w-full grid-cols-2">
										<TabsTrigger
											value="services"
											className="text-[#596574] data-[state=active]:border-primary data-[state=active]:text-primary"
										>
											Services
										</TabsTrigger>
										<TabsTrigger
											value="providers"
											className="text-[#596574] data-[state=active]:border-primary data-[state=active]:text-primary"
										>
											Providers
										</TabsTrigger>
									</TabsList>
									<TabsContent value="services">
										<ServiceSection
											services={
												appointmentDetails?.services
											}
											handleClick={handleServiceSelection}
										/>
									</TabsContent>
									<TabsContent value="providers">
										<ProviderSection
											providers={
												appointmentDetails?.stations
											}
											handleClick={
												handleProviderSelection
											}
										/>
									</TabsContent>
								</Tabs>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ServiceSelection;

const ServiceSection = ({ services, handleClick }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const debouncedSearchTerm = useDebouncedSearch(searchTerm, 300);

	const filteredServices = services?.filter((service) =>
		service?.name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
	);
	return (
		<div className="flex flex-col gap-y-4 pt-2">
			<div className="relative ">
				<span className="absolute left-2 top-2">
					<IoSearch className="text-sm text-[#858C95]" />
				</span>
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					disabled={!services?.length}
					className="w-full rounded-md border border-[#E5E5E7] py-1 pl-6 text-sm text-[#858C95]"
					placeholder="Quick search by name"
				/>
			</div>
			<div className="flex flex-col gap-y-2">
				<ul className="flex flex-col gap-y-2 ">
					{services?.length ? (
						filteredServices?.map((item) => (
							<li
								key={item?.id}
								className="flex cursor-pointer flex-col space-y-1 rounded-xl bg-primary/5 p-2"
								onClick={() => handleClick(item)}
							>
								<div className="flex items-center justify-between space-x-1">
									<Label className="text-sm font-medium text-[#09090B]">
										{item?.name}
									</Label>
									<ChevronRightIcon />
								</div>
								<p className=" line-clamp-2 text-xs text-[#858C95]">
									{item?.description ?? item?.address}
								</p>
							</li>
						))
					) : (
						<p className="mt-2 text-center text-sm ">
							No Services Available{" "}
						</p>
					)}
				</ul>
			</div>
		</div>
	);
};

const ProviderSection = ({ providers, handleClick }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const debouncedSearchTerm = useDebouncedSearch(searchTerm, 300);

	const filteredProviders = providers?.filter((provider) =>
		provider.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
	);
	return (
		<div className="flex flex-col gap-y-4 pt-2">
			<div className="relative ">
				<span className="absolute left-2 top-2">
					<IoSearch className="text-sm text-[#858C95]" />
				</span>
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="w-full rounded-md border border-[#E5E5E7] py-1 pl-6 text-sm text-[#858C95]"
					placeholder="Quick search by name"
				/>
			</div>
			<div className="flex flex-col gap-y-2">
				<ul className="flex flex-col gap-y-2">
					{providers?.length ? (
						filteredProviders?.map((item) => (
							<li
								key={item?.id}
								className="flex cursor-pointer flex-col space-y-1 rounded-xl bg-primary/5 p-2"
								onClick={() => handleClick(item)}
							>
								<div className="flex items-center justify-between space-x-1">
									<Label className="text-sm font-medium text-[#09090B]">
										{item?.name}
									</Label>
									<ChevronRightIcon />
								</div>
								{/* <p className=" text-xs text-[#858C95]">
									Description
								</p> */}
							</li>
						))
					) : (
						<p className="mt-2 text-center text-sm ">
							No Providers Available{" "}
						</p>
					)}
				</ul>
			</div>
		</div>
	);
};
