import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Country } from "country-state-city";
import {
	PatientWaitlistStatus,
	StatusOfPatient,
	StatusOfPatientType,
} from "./constants";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

/**
 * Formats a string input into MM/YY format for credit card expiration dates.
 * @param {Event} e - The input event object.
 * @returns {string} The formatted MM/YY string.
 */
export const formatStringToMMYY = (e: any): string => {
	const code = e.keyCode;
	const allowedKeys = [8];
	if (allowedKeys.indexOf(code) !== -1) {
		return "";
	}
	e.target.value = e.target.value
		.replace(/^([1-9]\/|[2-9])$/g, "0$1/")
		.replace(/^(0[1-9]|1[0-2])$/g, "$1/")
		.replace(/^([0-1])([3-9])$/g, "0$1/$2")
		.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
		.replace(/^([0]+)\/|[0]+$/g, "0")
		.replace(/[^\d/]|^[/]*$/g, "")
		.replace(/\/\//g, "/");
	return e.target.value;
};

/**
 * Formats a string of numbers into a credit card number format with spaces every 4 digits.
 * @param {string} string - The input string of numbers.
 * @returns {string} The formatted credit card number string.
 */
export const formatCreditCard = (string: string) => {
	const inputVal = string.replace(/ /g, "");
	let inputNumbersOnly = inputVal.replace(/\D/g, "");
	if (inputNumbersOnly.length > 20) {
		inputNumbersOnly = inputNumbersOnly.slice(0, 20);
	}
	const splits = inputNumbersOnly.match(/.{1,4}/g);
	let spacedNumber = "";
	if (splits) {
		spacedNumber = splits.join(" ");
	}
	return spacedNumber;
};

/**
 * An array of options for schedule block durations, from 15 to 75 minutes in 15-minute increments.
 * @type {Array<{value: number, label: string}>}
 */
export const scheduleBlockOptions = Array.from({ length: 5 }).map(
	(_, index) => ({
		value: (index + 1) * 15,
		label: ((index + 1) * 15).toString(),
	})
);

/**
 * Converts a hex color code to an RGB object.
 *
 * This function takes a hex color code as a string and converts it to an RGB object.
 * The hex code can be in the short format (`#RGB`) or the full format (`#RRGGBB`).
 * If the hex code is not valid, an error is thrown.
 *
 * @param {string} hex - The hex color code to convert. It can be in `#RGB` or `#RRGGBB` format.
 * @returns {{r: number, g: number, b: number}} - An object containing the red (r), green (g), and blue (b) values as numbers.
 * @throws {Error} If the hex color code is not valid.
 */
export const hexToRGB = (hex: string) => {
	hex = hex.replace(/^#/, "");
	let r, g, b;
	if (hex.length === 3) {
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex.length === 6) {
		r = parseInt(hex.slice(0, 2), 16);
		g = parseInt(hex.slice(2, 4), 16);
		b = parseInt(hex.slice(4, 6), 16);
	} else {
		throw new Error("Invalid HEX color.");
	}

	return { r, g, b };
};

/**
 * Changes the theme color by setting a CSS variable with the specified color.
 *
 * If the provided theme is "default", it will be replaced with a default color (`#005893`).
 * If the theme is a valid hex color (with or without `#`), it will be converted to its RGB equivalent
 * and the CSS `--primary` variable will be updated with the RGB values.
 *
 * @param {string} theme - The hex color code or the string "default" to set the theme color.
 * @returns {string} - The theme that was actually set (either the original hex value or the default color).
 */
export const changeTheme = (theme: string): string => {
	const defaultColor = "#005893";

	// Handle the default theme case
	if (theme === "default") {
		theme = defaultColor;
	}

	// Ensure the theme is in a proper hex format
	if (!theme.startsWith("#")) {
		theme = `#${theme}`;
	}

	// Validate the hex color format using a regex
	const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
	if (!hexColorRegex.test(theme)) {
		console.warn(
			`Invalid hex color: ${theme}. Falling back to default color.`
		);
		theme = defaultColor;
	}

	// Convert hex to RGB
	const { r, g, b } = hexToRGB(theme);
	document.documentElement.style.setProperty("--primary", `${r} ${g} ${b}`);

	return theme;
};

/**
 * An array of country dialing codes.
 *
 * This array contains dialing codes for 249 countries, formatted with a "+" sign
 * followed by the country code. Each object in the array includes the dialing code
 * as both the label and the value.
 *
 * @type {Record<string, string>[]}
 */
export const countryCodes = [
	{ label: "US", value: "+1" },
	{ label: "BS", value: "+1-242" },
	{ label: "BB", value: "+1-246" },
	{ label: "AI", value: "+1-264" },
	{ label: "AG", value: "+1-268" },
	{ label: "KY", value: "+1-345" },
	{ label: "BM", value: "+1-441" },
	{ label: "GD", value: "+1-473" },
	{ label: "GU", value: "+1-671" },
	{ label: "AS", value: "+1-684" },
	{ label: "LC", value: "+1-758" },
	{ label: "DM", value: "+1-767" },
	{ label: "VC", value: "+1-784" },
	{ label: "DO", value: "+1-809" },
	{ label: "TT", value: "+1-868" },
	{ label: "KN", value: "+1-869" },
	{ label: "JM", value: "+1-876" },
	{ label: "EG", value: "+20" },
	{ label: "SS", value: "+211" },
	{ label: "MA", value: "+212" },
	{ label: "DZ", value: "+213" },
	{ label: "TN", value: "+216" },
	{ label: "LY", value: "+218" },
	{ label: "GM", value: "+220" },
	{ label: "SN", value: "+221" },
	{ label: "MR", value: "+222" },
	{ label: "ML", value: "+223" },
	{ label: "GN", value: "+224" },
	{ label: "CI", value: "+225" },
	{ label: "BF", value: "+226" },
	{ label: "NE", value: "+227" },
	{ label: "TG", value: "+228" },
	{ label: "BJ", value: "+229" },
	{ label: "MU", value: "+230" },
	{ label: "LR", value: "+231" },
	{ label: "SL", value: "+232" },
	{ label: "GH", value: "+233" },
	{ label: "NG", value: "+234" },
	{ label: "TD", value: "+235" },
	{ label: "CF", value: "+236" },
	{ label: "CM", value: "+237" },
	{ label: "CV", value: "+238" },
	{ label: "ST", value: "+239" },
	{ label: "GQ", value: "+240" },
	{ label: "GA", value: "+241" },
	{ label: "CG", value: "+242" },
	{ label: "CD", value: "+243" },
	{ label: "AO", value: "+244" },
	{ label: "GW", value: "+245" },
	{ label: "SC", value: "+248" },
	{ label: "SD", value: "+249" },
	{ label: "RW", value: "+250" },
	{ label: "ET", value: "+251" },
	{ label: "SO", value: "+252" },
	{ label: "DJ", value: "+253" },
	{ label: "KE", value: "+254" },
	{ label: "TZ", value: "+255" },
	{ label: "UG", value: "+256" },
	{ label: "BI", value: "+257" },
	{ label: "MZ", value: "+258" },
	{ label: "ZM", value: "+260" },
	{ label: "MG", value: "+261" },
	{ label: "ZW", value: "+263" },
	{ label: "NA", value: "+264" },
	{ label: "MW", value: "+265" },
	{ label: "LS", value: "+266" },
	{ label: "BW", value: "+267" },
	{ label: "KM", value: "+269" },
	{ label: "ZA", value: "+27" },
	{ label: "ER", value: "+291" },
	{ label: "GR", value: "+30" },
	{ label: "NL", value: "+31" },
	{ label: "BE", value: "+32" },
	{ label: "FR", value: "+33" },
	{ label: "ES", value: "+34" },
	{ label: "PT", value: "+351" },
	{ label: "LU", value: "+352" },
	{ label: "IE", value: "+353" },
	{ label: "IS", value: "+354" },
	{ label: "AL", value: "+355" },
	{ label: "MT", value: "+356" },
	{ label: "CY", value: "+357" },
	{ label: "FI", value: "+358" },
	{ label: "BG", value: "+359" },
	{ label: "HU", value: "+36" },
	{ label: "LT", value: "+370" },
	{ label: "LV", value: "+371" },
	{ label: "EE", value: "+372" },
	{ label: "MD", value: "+373" },
	{ label: "AM", value: "+374" },
	{ label: "BY", value: "+375" },
	{ label: "AD", value: "+376" },
	{ label: "MC", value: "+377" },
	{ label: "SM", value: "+378" },
	{ label: "UA", value: "+380" },
	{ label: "RS", value: "+381" },
	{ label: "ME", value: "+382" },
	{ label: "HR", value: "+385" },
	{ label: "SI", value: "+386" },
	{ label: "BA", value: "+387" },
	{ label: "IT", value: "+39" },
	{ label: "RO", value: "+40" },
	{ label: "CH", value: "+41" },
	{ label: "CZ", value: "+420" },
	{ label: "SK", value: "+421" },
	{ label: "LI", value: "+423" },
	{ label: "AT", value: "+43" },
	{ label: "GB", value: "+44" },
	{ label: "DK", value: "+45" },
	{ label: "SE", value: "+46" },
	{ label: "NO", value: "+47" },
	{ label: "PL", value: "+48" },
	{ label: "DE", value: "+49" },
	{ label: "BZ", value: "+501" },
	{ label: "GT", value: "+502" },
	{ label: "SV", value: "+503" },
	{ label: "HN", value: "+504" },
	{ label: "NI", value: "+505" },
	{ label: "CR", value: "+506" },
	{ label: "PA", value: "+507" },
	{ label: "HT", value: "+509" },
	{ label: "PE", value: "+51" },
	{ label: "MX", value: "+52" },
	{ label: "CU", value: "+53" },
	{ label: "AR", value: "+54" },
	{ label: "BR", value: "+55" },
	{ label: "CL", value: "+56" },
	{ label: "CO", value: "+57" },
	{ label: "VE", value: "+58" },
	{ label: "BO", value: "+591" },
	{ label: "GY", value: "+592" },
	{ label: "EC", value: "+593" },
	{ label: "PY", value: "+595" },
	{ label: "SR", value: "+597" },
	{ label: "UY", value: "+598" },
	{ label: "MY", value: "+60" },
	{ label: "AU", value: "+61" },
	{ label: "ID", value: "+62" },
	{ label: "PH", value: "+63" },
	{ label: "NZ", value: "+64" },
	{ label: "SG", value: "+65" },
	{ label: "TH", value: "+66" },
	{ label: "TL", value: "+670" },
	{ label: "BN", value: "+673" },
	{ label: "NR", value: "+674" },
	{ label: "PG", value: "+675" },
	{ label: "TO", value: "+676" },
	{ label: "SB", value: "+677" },
	{ label: "VU", value: "+678" },
	{ label: "FJ", value: "+679" },
	{ label: "PW", value: "+680" },
	{ label: "NU", value: "+683" },
	{ label: "WS", value: "+685" },
	{ label: "KI", value: "+686" },
	{ label: "TV", value: "+688" },
	{ label: "FM", value: "+691" },
	{ label: "MH", value: "+692" },
	{ label: "RU", value: "+7" },
	{ label: "JP", value: "+81" },
	{ label: "KR", value: "+82" },
	{ label: "VN", value: "+84" },
	{ label: "KP", value: "+850" },
	{ label: "KH", value: "+855" },
	{ label: "LA", value: "+856" },
	{ label: "CN", value: "+86" },
	{ label: "BD", value: "+880" },
	{ label: "TR", value: "+90" },
	{ label: "IN", value: "+91" },
	{ label: "PK", value: "+92" },
	{ label: "AF", value: "+93" },
	{ label: "LK", value: "+94" },
	{ label: "MM", value: "+95" },
	{ label: "MV", value: "+960" },
	{ label: "LB", value: "+961" },
	{ label: "JO", value: "+962" },
	{ label: "SY", value: "+963" },
	{ label: "IQ", value: "+964" },
	{ label: "KW", value: "+965" },
	{ label: "SA", value: "+966" },
	{ label: "YE", value: "+967" },
	{ label: "OM", value: "+968" },
	{ label: "AE", value: "+971" },
	{ label: "IL", value: "+972" },
	{ label: "BH", value: "+973" },
	{ label: "QA", value: "+974" },
	{ label: "BT", value: "+975" },
	{ label: "MN", value: "+976" },
	{ label: "NP", value: "+977" },
	{ label: "IR", value: "+98" },
	{ label: "TJ", value: "+992" },
	{ label: "TM", value: "+993" },
	{ label: "AZ", value: "+994" },
	{ label: "GE", value: "+995" },
	{ label: "KG", value: "+996" },
	{ label: "UZ", value: "+998" },
];

const initialCountryOptions = Object.values(Country.getAllCountries())
	.map((country) => ({
		value: country.isoCode,
		label: country.name,
	}))
	.filter((country) => country.value !== "CA" && country.value !== "US");

/**
 * An array of country options.
 *
 * This array contains all available countries, where each object includes the
 * country's ISO code as the value and the country's name as the label.
 *
 * @type {Array<{ value: string; label: string }>}
 */
export const countryOptions = [
	{ value: "CA", label: "Canada" },
	{ value: "US", label: "United States" },
	...initialCountryOptions,
];

/**
 * Extracts the initials from a given name.
 *
 * @param {string} name - The full name from which to extract initials.
 * @param {boolean} [includeSecondNameInitial=false] - Whether to include the first letter of the second name.
 * @returns {string} The initials extracted from the name.
 *
 * @example
 * // returns 'J'
 * getInitials('John Doe');
 *
 * @example
 * // returns 'JD'
 * getInitials('John Doe', true);
 *
 * @example
 * // returns 'A'
 * getInitials('Alice');
 */
export const getInitials = (
	name: string,
	includeSecondNameInitial: boolean = false
): string => {
	const nameParts = name.trim().split(" ");

	// Extract the first letter of the first name
	let initials = nameParts[0]?.charAt(0).toUpperCase() || "";

	// If includeSecondNameInitial is true and there's a second name, add its initial
	if (includeSecondNameInitial && nameParts.length > 1) {
		initials += nameParts[1]?.charAt(0).toUpperCase() || "";
	}

	return initials;
};

/**
 * Retrieves the corresponding key from `StatusOfPatient` based on the provided status.
 *
 * This function searches through the keys of `StatusOfPatient` to find the key whose value matches
 * the provided `status`. The matching key is then used to look up the colors in `PatientWaitlistStatus`.
 *
 * @param {string} status - The current status of the patient from the waitlist.
 * @returns {keyof typeof PatientWaitlistStatus} - The key from `PatientWaitlistStatus` that corresponds to the provided status.
 */
export const patientStatusKey: (
	status: StatusOfPatientType
) => keyof typeof PatientWaitlistStatus = (status) =>
	Object.keys(StatusOfPatient).find(
		(key) => StatusOfPatient[key as keyof typeof StatusOfPatient] === status
	) as keyof typeof PatientWaitlistStatus;
