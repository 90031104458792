import React, { useEffect, useState } from "react";
import FormLayout from "./components/FormLayout";
import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import TimeSlot from "./components/TimeSlot";
import { Calendar } from "@/src/components/ui/calendar";
import {
	useCompleteReservation,
	useGetServiceAvailableTimeSlots,
	useReserveTimeslot,
} from "@/src/store/slices/scheduleSlice";
import { useFormContext } from "./helpers/ScheduleFormContext";
import { cn } from "@/src/utils/general";
import Loader from "@/src/components/Loader/Loader";
import moment from "moment";
import CustomSelect from "@/src/components/common/CustomSelect";
import { useParams } from "react-router";
import ConfirmAppointmentModal from "./components/ConfirmAppointmentModal";
import useCustomToast from "@/src/components/CustomToast";
import { AxiosResponse } from "axios";

const BookProviderTime = () => {
	const { scheduling_code } = useParams();
	const {
		setStep,
		appointmentDetails,
		bookingType,
		selectedStation,
		validationCode,
		validationDetails,
		setScheduleCode,
		setSubmissionDetails,
		setFormsData,
		reset,
		setTemporarySlotToken,
	} = useScheduleStore();
	const { formData, setFormData } = useFormContext();
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		formData?.appointmentDetails?.date
			? new Date(formData?.appointmentDetails?.date)
			: new Date()
	);
	const [selectedService, setSelectedService] = useState<{
		label: string;
		value: string;
		appointment_methods: { id: string; name: string }[];
	} | null>(null);
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	const [waitlistChecked, setWaitlistChecked] = useState<boolean>(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);
	const [selectedAppointmentMethod, setSelectedAppointmentMethod] = useState<{
		label: string;
		value: string;
	} | null>(null);
	const formattedDate = selectedDate
		? moment(selectedDate).startOf("day").format("YYYY-MM-DD")
		: undefined;
	const toast = useCustomToast();

	const booking_code: string =
		bookingType == "station"
			? scheduling_code || selectedStation?.schedule_code
			: selectedStation?.schedule_code;

	const location_id =
		bookingType == "location"
			? selectedStation?.location_id
			: bookingType == "station"
				? appointmentDetails?.location?.id
				: appointmentDetails?.id;

	const services =
		bookingType != "station"
			? selectedStation?.services
			: appointmentDetails?.services;

	const {
		data: timeslotsData,
		isLoading: isTimeSlotsLoading,
		isSuccess: isTimeSlotsSuccess,
		error,
	} = useGetServiceAvailableTimeSlots({
		...((validationDetails?.length || validationCode != "") && {
			customer_verification_token: validationCode,
		}),
		date: formattedDate,
		locationId: location_id,
		serviceId: selectedService
			? parseInt(selectedService?.value)
			: undefined,
		stationId:
			bookingType == "station"
				? appointmentDetails?.id
				: selectedStation?.id,
	});

	const timeslots = timeslotsData?.data || appointmentDetails?.time_slots;

	const handleAppointmentMethodChange = (selectedOption: any) => {
		setSelectedAppointmentMethod(selectedOption);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				appointmentMethodId: selectedOption?.value,
			},
		}));
	};

	const handleServiceTypeChange = (selectedOption: any) => {
		if (selectedOption != selectedService) {
			setSelectedTime(null);
			setSelectedAppointmentMethod(null);
			setFormData((prevData) => ({
				...prevData,
				appointmentDetails: {
					...prevData.appointmentDetails,
					timeslot: null,
				},
			}));
		}
		setSelectedService(selectedOption);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				serviceType: selectedOption?.value,
			},
		}));
	};

	const handleDateChange = (date: Date | undefined) => {
		if (!date) return;

		const formattedDate = moment(date).startOf("day").format("YYYY-MM-DD");
		setSelectedDate(date);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	};

	const handleTimeSlotChange = (time: string) => {
		setSelectedTime(time);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				timeslot: time,
			},
		}));
	};

	const handleCloseConfirmationModal = () => {
		setIsConfirmationModalOpen(false);
	};

	const handleBookAppointment = (e: React.FormEvent) => {
		e.preventDefault();
		const data = {
			schedule_code: booking_code,
			is_on_notification_waitlist: waitlistChecked,
			service_id: formData.appointmentDetails.serviceType,
			...(formData.appointmentDetails.appointmentMethodId !== null && {
				appointment_method_id:
					formData.appointmentDetails.appointmentMethodId,
			}),
			date: formattedDate ? formattedDate : "",
			start_time: formData.appointmentDetails.timeslot.slice(0, -3),
			...((validationDetails?.length || validationCode != "") && {
				customer_verification_token: validationCode,
			}),
		};

		reserveTimeslot(
			{
				scheduling_code: booking_code,
				data,
			},
			{
				onSuccess: (data) => {
					setScheduleCode(data?.data?.schedule_code || booking_code);
					setTemporarySlotToken(data?.data?.token || undefined);
					setFormsData(data?.data?.form);
					if (
						data?.data?.form === null &&
						data?.data?.custom_intakes?.length < 1
					) {
						handleSubmit(data?.data?.token);
					} else {
						setStep(3);
					}
				},
			}
		);
	};

	const {
		mutate: completeReservation,
		isPending: isCompleteReservationPending,
	} = useCompleteReservation({
		onSuccess: () => {
			return;
		},
	});

	const { mutate: reserveTimeslot, isPending: isReserveTimeslotPending } =
		useReserveTimeslot();

	const handleSubmit = (temporarySlotToken) => {
		completeReservation(
			{
				slot_token: temporarySlotToken as string,
				data: {
					...((!validationDetails?.length ||
						validationCode == "") && {
						full_name: formData?.patientDetails?.full_name,
						phone_number: formData?.patientDetails?.phone_number,
						email: formData?.patientDetails?.email,
					}),
				},
			},
			{
				onSuccess: (data: AxiosResponse) => {
					reset();
					setSubmissionDetails(data.data);
					toast("Customer scheduled successfully", {
						type: "success",
						id: "schedule-submission",
					});
					setStep(4);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message || "An error occured";
					toast(errorMessage, {
						type: "error",
						id: "schedule-submission",
					});
				},
			}
		);
	};

	useEffect(() => {
		setSelectedTime(null);
	}, [formattedDate]);

	useEffect(() => {
		if (isTimeSlotsSuccess && timeslotsData?.reason) {
			const message = timeslotsData?.reason;
			toast(message, {
				id: "timeslot-error",
				type: "error",
			});
		}
	}, [isTimeSlotsSuccess]);

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	}, []);

	return (
		<FormLayout>
			<form
				onSubmit={handleBookAppointment}
				className="bg-primary-3 flex flex-col gap-y-4 px-6 pb-12 pt-6 font-hoves md:h-[100svh] md:overflow-auto lg:h-auto lg:px-12 2xl:h-screen"
			>
				<div className="h-full w-full items-center justify-center gap-6 pb-4 align-top md:items-start lg:flex">
					<div className="flex flex-col gap-y-5 md:flex-[0.55]">
						<div className="border-primary-3 flex flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
							<div className="flex items-center gap-x-2 ">
								<i className="mgc_building_1_line before:!text-primary" />
								<p className="text-sm font-semibold text-main-1">
									{bookingType == "station"
										? appointmentDetails?.location?.name
										: appointmentDetails?.name}
								</p>
							</div>
							<div className="flex items-center gap-x-2 ">
								<i className="mgc_location_line before:!text-primary" />
								<p className="text-sm text-[#6D748D]">
									{bookingType == "station"
										? appointmentDetails?.location?.address
										: appointmentDetails?.address}
								</p>
							</div>
							<div className="flex items-center gap-x-2">
								<i className="mgc_store_line before:!text-[#043B6D]" />
								<p className="text-sm text-main-1">
									{bookingType == "station"
										? appointmentDetails?.name
										: selectedStation?.name}
								</p>
							</div>
						</div>
						<div className=" border-primary-3  flex flex-col gap-y-2 rounded-[12px] bg-white px-4 py-6">
							<p className="font-medium text-main-1">
								Select Service
							</p>
							<CustomSelect
								className="w-full"
								placeholder="Select service"
								isDisabled={!services?.length}
								options={services?.map((service) => ({
									value: service.id,
									label: service.name,
									appointment_methods:
										service.appointment_methods,
								}))}
								value={selectedService}
								onChange={handleServiceTypeChange}
							/>
						</div>
						<div className=" border-primary-3  flex flex-col gap-y-2 rounded-[12px] bg-white px-4 py-6">
							<p className="font-medium text-main-1">
								Select Appointment Method
							</p>
							<CustomSelect
								className="w-full"
								placeholder="Select Appointment Method"
								options={selectedService?.appointment_methods?.map(
									(type) => ({
										value: type.id,
										label: type.name,
									})
								)}
								isDisabled={
									!selectedService?.appointment_methods
										?.length
								}
								value={selectedAppointmentMethod}
								onChange={handleAppointmentMethodChange}
							/>
						</div>

						<div className="w-full justify-center rounded-md border bg-white px-4 py-2">
							<h5 className="font-medium text-main-1 ">
								Select Your Appointment Date
							</h5>
							<div className="flex  w-full pb-4 md:justify-start lg:justify-center">
								<Calendar
									mode="single"
									className="flex h-full w-full"
									classNames={{
										months: "flex w-full flex-col sm:flex-row gap-y-4 sm:gap-x-4 sm:space-y-0 flex-1",
										month: "space-y-4 w-full flex flex-col",
										table: "w-full h-full border-collapse space-y-1",
										head_row: "",
										row: "w-full mt-2",
									}}
									selected={selectedDate}
									onSelect={handleDateChange}
									disabled={{ before: new Date() }}
								/>
							</div>
						</div>
					</div>

					<div className="mt-4 flex w-full flex-col gap-y-3 pt-4 md:flex-[0.45] md:pt-0">
						<div className=" h-auto w-full overflow-y-auto rounded-[12px] bg-white px-6 py-6 font-hoves   md:max-h-[400px] lg:max-h-[450px]">
							<p className="text-base font-medium leading-[30px] -tracking-1% text-main-1">
								Select your Appointment Time
							</p>
							<>
								{isTimeSlotsLoading && (
									<div className="mx-auto my-3 flex h-full w-full items-center justify-center self-center">
										<Loader size={25} />
									</div>
								)}

								{error && (
									<p className="w-full py-4 text-center">
										Error loading time slots
									</p>
								)}

								{!isTimeSlotsLoading &&
								!error &&
								timeslots?.length > 1 ? (
									<div className="mx-auto mt-4 flex w-full flex-wrap justify-start gap-x-2 gap-y-4">
										{timeslots?.map(
											(timeslot: any, i: number) => (
												<TimeSlot
													selected={
														selectedTime ===
															timeslot.start_time ||
														formData
															?.appointmentDetails
															?.timeslot ===
															timeslot.start_time
													}
													onClick={() =>
														handleTimeSlotChange(
															timeslot.start_time
														)
													}
													className="w-[144px]"
													timeslot={timeslot}
													key={i}
												/>
											)
										)}
									</div>
								) : (
									<p
										className={cn("py-4", {
											hidden: isTimeSlotsLoading || error,
										})}
									>
										No timeslots available for this date.
									</p>
								)}
							</>
						</div>
						<div className="flex flex-col gap-y-2 pt-4">
							<div className="flex flex-col gap-y-2">
								<p className="text-sm font-light">
									Would you like to be added to the waitlist?
								</p>
								<div className="flex items-center gap-2 font-light ">
									<input
										type="checkbox"
										className="accent-primary"
										checked={waitlistChecked}
										onChange={() => {
											setWaitlistChecked(
												!waitlistChecked
											);
											setFormData((prevData) => ({
												...prevData,
												appointmentDetails: {
													...prevData.appointmentDetails,
													waitlist_checked:
														!waitlistChecked,
												},
											}));
										}}
									/>
									<p className="text-sm text-[#757575]">
										Yes, if an appointment opens up before
										my selected time, please notify me.
									</p>
								</div>
							</div>
							<div className="">
								<Button
									className="mt-6 w-full bg-primary px-6 py-1.5"
									type="button"
									disabled={
										!formData?.appointmentDetails?.date ||
										!formData?.appointmentDetails?.timeslot
									}
									onClick={() =>
										setIsConfirmationModalOpen(
											!isConfirmationModalOpen
										)
									}
								>
									{isReserveTimeslotPending ||
									isCompleteReservationPending ? (
										<Loader size={14} />
									) : (
										"Schedule Appointment"
									)}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<ConfirmAppointmentModal
				isOpen={isConfirmationModalOpen}
				handleCloseModal={handleCloseConfirmationModal}
				handleReserveTimeslot={handleBookAppointment}
				data={{
					appointment_date: formData?.appointmentDetails?.date,
					appointment_time: formData?.appointmentDetails?.timeslot,
				}}
				isLoading={
					isReserveTimeslotPending || isCompleteReservationPending
				}
			/>
		</FormLayout>
	);
};

export default BookProviderTime;
