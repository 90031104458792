import Loader from "@/src/components/Loader/Loader";
import { Button } from "@/src/components/ui/button";
import CustomIntakeField from "@/src/components/Waitlist/CustomIntakeField";
import useScheduleStore from "@/src/store/useScheduleStore";
import { CustomIntake } from "@/src/types/custom-intakes";
import { createScheduleCustomFieldsSchema } from "@/src/types/schedule";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import { useFormContext } from "./helpers/ScheduleFormContext";

const IntakeForm = ({
	handleSubmission,
	isCompleteReservationPending,
	tokenInformation,
	isTokenRetrievalSuccess,
}) => {
	const { scheduling_code } = useParams();
	const { formData, setFormData } = useFormContext();

	const navigate = useNavigate();

	const { validationCode, validationDetails } = useScheduleStore();

	const [customFieldsSchema, setCustomFieldsSchema] = useState(
		createScheduleCustomFieldsSchema([])
	);
	useEffect(() => {
		if (tokenInformation?.data === null) {
			return;
		}
		if (isTokenRetrievalSuccess && tokenInformation?.data?.custom_intakes) {
			const newSchema = createScheduleCustomFieldsSchema(
				tokenInformation?.data?.custom_intakes
			);
			setCustomFieldsSchema(newSchema);
		}
	}, [
		scheduling_code,
		navigate,
		isTokenRetrievalSuccess,
		tokenInformation?.data,
	]);

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid },
	} = useForm<z.infer<typeof customFieldsSchema>>({
		resolver: zodResolver(customFieldsSchema),
		mode: "onChange",
		shouldUnregister: true,
		reValidateMode: "onChange",
		defaultValues: {
			custom_intakes: {},
		},
	});

	const customIntakesFormat = (data: z.infer<typeof customFieldsSchema>) => {
		if (!data?.custom_intakes) return undefined;
		const fullCustomIntakes: Record<string, any> = {};
		tokenInformation?.data?.custom_intakes?.forEach((intake) => {
			if (!data?.custom_intakes) return undefined;
			const value = data?.custom_intakes[intake.key];

			if (value === "" || value === undefined) return;
			if (intake.type === "infoText") {
				return;
			}
			switch (intake.type) {
				case "text":
					if (typeof value === "string" && value.trim() !== "") {
						fullCustomIntakes[intake.key] = value.trim();
					}
					break;
				case "numeric":
					fullCustomIntakes[intake.key] = value;
					break;

				case "boolean":
					fullCustomIntakes[intake.key] = value;
					break;

				case "date":
					fullCustomIntakes[intake.key] =
						value && value instanceof Date
							? value.toISOString().split("T")[0]
							: value;
					break;

				case "date_range":
					if (
						value &&
						typeof value === "object" &&
						"from" in value &&
						"to" in value
					) {
						fullCustomIntakes[intake.key] = `${
							value.from instanceof Date
								? value.from.toISOString().split("T")[0]
								: value.from
						} - ${
							value.to instanceof Date
								? value.to.toISOString().split("T")[0]
								: value.to
						}`;
					}
					break;

				case "dropdown":
					fullCustomIntakes[intake.key] = Array.isArray(value)
						? value.map((v) => v.value)
						: undefined;
					break;
				case "checkbox":
					fullCustomIntakes[intake.key] = value;
					break;

				case "attachment":
					// Handle file attachment (you might need to implement file upload logic)
					fullCustomIntakes[intake.key] =
						value instanceof File ? value.name : value;
					break;

				default:
					fullCustomIntakes[intake.key] = value;
			}
		});

		return fullCustomIntakes;
	};

	const onSubmit: SubmitHandler<z.infer<typeof customFieldsSchema>> = async (
		data
	) => {
		if (!validationDetails?.length && !validationCode) {
			if (Object.keys(formData).length < 2) {
				return navigate(`/schedule/${scheduling_code}`);
			}
		}

		const formattedCustomIntakes = customIntakesFormat(data);
		await new Promise<void>((resolve) => {
			setFormData((prevData) => {
				const newData = {
					...prevData,
					custom_intakes: formattedCustomIntakes,
				};
				resolve();
				return newData;
			});
		});

		handleSubmission();
	};

	const proceedToFormFlow = React.useCallback(() => {
		handleSubmission();
	}, [handleSubmission]);

	const customIntakesValues = watch("custom_intakes");

	const areAllFieldsFilled = () => {
		if (!tokenInformation?.data?.custom_intakes) {
			return false;
		}

		const fields = tokenInformation?.data?.custom_intakes;

		const hasOnlyInfoTextFields = fields.every(
			(field) => field.type === "infoText"
		);

		const hasOnlyInfoImage = fields.every(
			(field) => field.type === "infoImage"
		);

		if (hasOnlyInfoImage) {
			return true;
		}
		if (hasOnlyInfoTextFields) {
			return false;
		}

		return fields?.every((field) => {
			const value = customIntakesValues && customIntakesValues[field.key];
			if (field.type === "infoText") {
				return true;
			}
			switch (field.type) {
				case "text":
				case "numeric":
					return value !== undefined && value !== "";
				case "dropdown":
					return Array.isArray(value) && value.length > 0;
				case "date":
					return value !== null && value !== undefined;
				case "date_range":
					return value?.from && value?.to;
				case "boolean":
				case "infoText":
					return true;
				case "infoImage":
					return true;
				case "checkbox":
					return value !== null || value !== undefined;
				default:
					return (
						value !== null && value !== undefined && value !== ""
					);
			}
		});
	};

	useEffect(() => {
		if (
			isTokenRetrievalSuccess &&
			tokenInformation?.data?.custom_intakes?.length === 0
		) {
			proceedToFormFlow();
		}
	}, [
		isTokenRetrievalSuccess,
		tokenInformation?.data?.custom_intakes,
		proceedToFormFlow,
	]);

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="mx-auto my-4 flex w-full max-w-[95%] flex-col gap-y-3 rounded-md bg-white p-4 md:max-w-[80%]"
			>
				{tokenInformation?.data?.custom_intakes?.length ? (
					<>
						<div className="flex flex-col gap-y-2">
							<h1 className="font-semibold text-main-1">
								Now, For Some More Details
							</h1>
							<p className="max-w-[294px] text-[#6D748D]">
								Only your first name will be shown on the
								waitlist community
							</p>
						</div>
						<div className="flex flex-col gap-y-4">
							{tokenInformation?.data?.custom_intakes?.map(
								(custom, idx) => (
									<CustomIntakeField
										key={idx}
										intake={custom as CustomIntake}
										register={register}
										errors={errors}
										control={control}
									/>
								)
							)}
						</div>

						<Button
							className="bg-primary"
							type="submit"
							disabled={
								isCompleteReservationPending ||
								!isValid ||
								!areAllFieldsFilled()
							}
						>
							{isCompleteReservationPending ? (
								<Loader size={18} />
							) : (
								"Save"
							)}
						</Button>
						{/* <Button
							type="button"
							variant={"outline"}
							disabled={hasRequiredFields()}
							onClick={() => {
								setSubmissionType("skip");
								submitWithoutCustomFields();
							}}
						>
							{submissionType === "skip" &&
							isCompleteReservationPending ? (
								<Loader size={18} />
							) : (
								"Skip"
							)}
						</Button> */}
					</>
				) : (
					<>
						<p className="pb-4 text-center  text-lg  text-primary">
							No custom fields available? Please proceed to
							schedule{" "}
						</p>

						<Button
							className="bg-primary"
							type="submit"
							disabled={isCompleteReservationPending || !isValid}
						>
							{isCompleteReservationPending ? (
								<Loader size={18} />
							) : (
								"Schedule Appointment"
							)}
						</Button>
					</>
				)}
			</form>
		</>
	);
};

export default IntakeForm;
