import React from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/src/components/ui/dialog";
import { Button } from "@/src/components/ui/button";
import Loader from "@/src/components/Loader/Loader";

const ScheduleOptimizerModal = ({
	isOpen,
	onClose,
	onGoToAppointment,
	status,
}) => {
	return (
		<Dialog
			open={isOpen}
			onOpenChange={onClose}
			aria-describedby="submission-block-modal"
		>
			<DialogContent className="flex w-full max-w-[360px] rounded-[10px] px-3 py-4 pl-6">
				<div className="flex w-full gap-2">
					<div className="flex-1">
						<div className="flex w-full flex-col gap-y-2">
							<DialogHeader>
								<DialogTitle className="flex !w-full items-center justify-between">
									<span className="w-full text-xl font-semibold leading-[30px] tracking-[-0.22px]">
										{status === "success" &&
											"Appointment Confirmed"}
										{status === "error" &&
											"Slot Has Already Has Been Booked"}
										{status === "loading" &&
											"Loading Schedule"}
									</span>
								</DialogTitle>
								<DialogDescription className="sr-only">
									Schedule optimizer
								</DialogDescription>
							</DialogHeader>

							{status == "loading" && (
								<div className="flex w-full flex-col  justify-center text-center">
									<Loader size={20} />
									<p>Updating your appointment...</p>
								</div>
							)}

							{status == "success" && (
								<>
									<p className="text-sm tracking-[-0.1px] text-main-7">
										Your appointment has been succesfully
										booked!
									</p>
									<p className="text-sm tracking-[-0.1px] text-main-7">
										We look forward to seeing you at your
										selecte time. If you need to make any
										changes or cancel your appointment,
										please use the link below.
									</p>
								</>
							)}
							{status == "error" && (
								<>
									<p className="text-sm tracking-[-0.1px] text-main-7">
										The time slot you selected has already
										been booked by someone else, as
										appointments are allocated on a
										first-to-book basis
									</p>
									<p className="py-3 text-sm  tracking-[-0.1px] text-main-7">
										Don&apos;t worry—you&apos;re still on
										the waitlist and will be notified if
										another slots becomes available. Thanks!
									</p>
								</>
							)}
						</div>
						<DialogFooter className="mt-2 flex w-full !flex-col !items-center  gap-y-2">
							{status === "success" && (
								<Button
									className="w-full"
									onClick={onGoToAppointment}
								>
									View Appointment
								</Button>
							)}
							{status === "error" && (
								<Button className="w-full" onClick={onClose}>
									Close
								</Button>
							)}
						</DialogFooter>
					</div>
					<DialogClose className="flex h-fit w-fit items-start">
						<i className="mgc_close_line mt-1.5 text-lg before:!text-main-4" />
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ScheduleOptimizerModal;
