import React from "react";
import { format } from "date-fns";
import { DateRange } from "react-day-picker";
import { cn } from "@/src/utils/general";
import { Button } from "@/src/components/ui/button";
import { Calendar } from "@/src/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/src/components/ui/popover";
import { LuCalendar } from "react-icons/lu";

interface DateRangePickerProps {
	value: DateRange | undefined;
	onChange: (date: DateRange | undefined) => void;
	className?: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
	value,
	onChange,
	className = "",
}) => {
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					id="date"
					variant="outline"
					className={cn(
						"w-fit justify-start text-left font-normal",
						!value && "",
						className
					)}
				>
					{value?.from ? (
						value.to ? (
							<>
								{format(value.from, "LLL dd, y")} -{" "}
								{format(value.to, "LLL dd, y")}
							</>
						) : (
							format(value.from, "LLL dd, y")
						)
					) : (
						<span>Pick a date range</span>
					)}
					<LuCalendar className="ml-auto h-4 w-4" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" align="start">
				<Calendar
					initialFocus
					mode="range"
					defaultMonth={value?.from}
					selected={value}
					onSelect={onChange}
					numberOfMonths={2}
				/>
			</PopoverContent>
		</Popover>
	);
};

export default DateRangePicker;
